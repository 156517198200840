@import 'rupture'
@import './mixins/mixin.styl'
@import './fonts.styl'

/*
Author: Булатасов Вадим
Name project: Детские кресла
*/

// Adaptive

flex-version = flex
support-for-ie = false
vendor-prefixes = official

rupture.scale = 0 576px 768px 992px 1200px
rupture.scale-names = 'xs' 'sm' 'md' 'lg' 'xl'

*
	padding 0
	margin 0
	box-sizing: border-box

	&::after
		padding 0
		margin 0
		box-sizing: border-box

	&::before
		padding 0
		margin 0
		box-sizing: border-box

	&:focus
		outline none

	img
		display block
		width 100%
		height auto

a
	display block
	text-decoration none
	color defColor

	&:hover
		color defColor
		text-decoration none


ol,
ul
	margin-left 30px

textarea:focus,
input:focus
	outline none

html
	font-size 16px

body
	line-height rem(20px)
	font-family defFont
	color: defColor
	size 100%
	overflow-x hidden

//styles

.container
	+above(xl)
		max-width 1170px !important

//hiding h-titles
.visually-hidden
	position absolute
	clip rect(0 0 0 0)
	width 1px
	height 1px
	margin -1px

.header
	position absolute
	top 0
	left 0
	width 100%

	&:before
		content ''
		position absolute
		top 0
		left 0
		z-index bsLevel
		width 100%
		height 142px
		background-color secColor
		border-bottom-left-radius 150px
		border-bottom-right-radius 150px
		+below(lg)
			border-radius 0
		+below(md)
			display none

	.header-container
		.top-menu
			position relative
			z-index medLevel
			display flex
			justify-content space-between
			align-items center
			padding 15px 0 25px
			transition .2s

			+below(md)
				position absolute
				top 98px
				left 0
				display block
				width 100%
				background-color secColor
				padding 0 15px
				transform translate(0%, -100%)
				will-change transform

			+below(sm)
				top 70px

			.search-block
				width 195px
				height 32px
				padding-right 30px
				font normal rem(16px) ltFont, sans-serif
				color vsColor
				background url("/assets/img/icons/search.svg") no-repeat center right 3px
				background-size 23px
				border none
				border-bottom 1px solid vsColor

				+below(md)
					margin 25px 0

				+below(sm)
					width 100%

				&::-webkit-input-placeholder
					font normal rem(16px) ltFont, sans-serif
					color vsColor

			.top-links
				display flex
				align-items center
				flex-wrap wrap

				+below(lg)
					padding 0 15px

					.top-link-item
						margin-bottom 15px

				+below(md)
					display none

			.top-link-item
				position relative
				margin-right 35px
				font normal rem(16px) defFont, sans-serif
				line-height rem(22px)
				color vsColor

				&:after
					content ''
					position absolute
					bottom: -3px
					left 0
					height 2px
					width 0
					background vsColor
					transition .2s

				&:hover
					&:after
						width 100%

			.mobile-links

				+above(lg)
					display none

				.top-link-item
					margin 13px 0

					&:first-child
						margin-top 0

			.top-address
				padding-left 25px

				.address-text
					position relative
					font bold rem(18px) / 1.5 bdFont, sans-serif
					color vsColor

					&:before
						content ''
						position absolute
						top 5px
						left -25px
						width 15px
						height 20px
						background url("/assets/img/icons/map.svg") no-repeat center
						background-size contain


				.address-link
					position relative
					display inline
					font normal rem(16px) / 1.5 defFont, sans-serif
					color vsColor
					+below(md)
						display none

					&:before
						content ''
						position absolute
						bottom -3px
						height 0
						width 100%
						border-bottom 1px dashed brdColor

					&:hover
						&:before
							border-bottom 1px solid brdColor


			.mobile-phone
				flex-shrink 0
				padding-left 30px
				margin 30px 0
				+above(lg)
					display none

				.phone-text
					position relative
					font bolr rem(18px) / 1.5 bdFont, sans-serif
					color vsColor

					&:before
						content ''
						position absolute
						top 5px
						left -30px
						width 25px
						height 20px
						background url("/assets/img/icons/phone-white.svg") no-repeat center
						background-size contain


				.call-link
					position relative
					display inline
					font normal rem(16px) / 1.5 defFont, sans-serif
					color vsColor

					&:before
						content ''
						position absolute
						bottom -3px
						height 0
						width 100%
						border-bottom 1px dashed vsColor

					&:hover
						&:before
							border-bottom 1px solid vsColor


			.mobile-socials
				display flex
				flex-wrap wrap
				margin-bottom 39px
				+above(lg)
					display none

				.social-link
					height 29px
					margin-right 25px

					img
						width auto
						height 100%


		.top-menu.slide
			transform translate(0%, 0%)

		.main-menu
			position relative
			z-index medLevel
			display flex
			justify-content space-between
			align-items center
			margin-right -48px
			margin-left -48px
			padding-right 48px
			padding-left 48px
			height 98px
			background bgColor
			border-radius 50px
			box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)

			+below(lg)
				margin 0

			+below(md)
				position absolute
				left 0
				width 100%
				height 98px
				border-radius 0
				padding 0 15px
			+below(sm)
				height 70px

			.logo-block
				flex-shrink 0
				max-height 63px
				+below(md)
					flex-grow 1

				img
					width auto
					height 100%

				+below(sm)
					height 45px

			.main-links
				display flex
				align-items center
				flex-wrap wrap

				+below(lg)
					justify-content space-between

				+below(md)
					display none

				.main-link-item
					position relative
					margin-right 35px
					font normal rem(16px) defFont, sans-serif
					line-height rem(22px)
					color defColor

					&:after
						content ''
						position absolute
						bottom: -3px
						left 0
						height 2px
						width 0
						background defColor
						transition .2s

					&:hover
						&:after
							width 100%


					+below(lg)
						margin 5px 35px

			.main-phone
				flex-shrink 0
				width 210px
				padding-left 30px

				+below(md)
					width auto
					padding-left 0

				.phone-text
					position relative
					font bold rem(18px) / 1.5 bdFont, sans-serif
					color defColor

					+below(md)
						display none

					&:before
						content ''
						position absolute
						top 5px
						left -30px
						width 25px
						height 20px
						background url("/assets/img/icons/phone-green.svg") no-repeat center
						background-size contain


				.call-link
					position relative
					display inline
					flex-shrink 0
					font normal rem(16px) / 1.5 defFont, sans-serif
					color defColor

					&:before
						content ''
						position absolute
						bottom -3px
						height 0
						width 100%
						border-bottom 1px dashed defColor

						+below(md)
							display none

					+below(md)
						display block
						width 35px
						height 35px
						margin-right 29px
						background url("/assets/img/icons/phone-green.svg") no-repeat center
						background-size contain

					+below(sm)
						width 24px
						height 24px

					&:hover
						&:before
							border-bottom 1px solid defColor

					span
						+below(md)
							display none

			.mobile-ctrl
				width 40px
				height 40px
				background url("/assets/img/icons/burger.svg") no-repeat center
				background-size contain
				opacity 1
				transition .1s

				+above(lg)
					display none

				+below(sm)
					width 30px
					height 30px

			.mobile-ctrl.close
				background url("/assets/img/icons/cross.svg") no-repeat center
				background-size contain


//universal elements
.menu-link
	display flex
	justify-content center
	align-items center
	width 205px
	height 46px
	border 1px solid trdColor
	border-radius 70px
	transition .4s

	span
		display flex
		align-items center
		padding 6px 20px
		font normal rem(16px) ttFont, sans-serif
		line-height rem(22px)
		color defColor
		border-radius 70px
		gradMxn(rgb(255, 168, 54), rgb(255, 204, 99))

		img
			flex-shrink 0
			margin-left 15px
			width 20px
			height 14px


.slide-btn-wrapper
	display block
	height 24px
	margin-top 15px
	border-bottom 2px dashed brdColor

	.slide-btn
		display block
		width 225px
		height 44px
		margin 0 auto
		font normal rem(20px) stFont, sans-serif
		line-height rem(40px)
		color defColor
		background-color vsColor
		border 2px solid defColor
		border-radius 60px
		transition .3s

		&:hover
			background-color defColor
			color vsColor

		+below(xs)
			background-color defColor
			color vsColor

		&:focus
			outline none

.slide-btn-wrapper.hidden
	display none

.std-pagination
	display flex
	margin-top 15px

	.page-item
		display flex
		justify-content center
		align-items center
		width 50px
		height 50px
		margin-right 10px
		font normal rem(18px) ttFont, sans-serif
		line-height rem(46px)
		color defColor
		text-align center
		border 2px solid trdColor
		border-radius 5px
		cursor pointer
		transition .3s

		&:hover
			background trdColor

	.page-item.active
		background trdColor

.show-filters
	margin-bottom 30px
	transition .3s

	.show-wrapper
		display flex
		padding 15px
		+below(md)
			display none

	.show-btn
		display block
		width 100%
		height 56px
		background-color #536377
		border none
		border-radius 5px

		&:focus
			outline none

		.btn-text
			width 100%
			height 100%
			font normal rem(20px) stFont, sans-serif
			line-height rem(56px)
			color vsColor
			background url("/assets/img/icons/list.svg") no-repeat center right 35px

			&:last-child
				display none

		+above(lg)
			display none

	.show-group
		margin-right 40px
		font normal rem(18px) defFont, sans-serif
		line-height rem(26px)
		color defColor
		border-bottom 2px dashed defColor
		transition .3s
		cursor pointer

		&:hover
			color secColor
			border-bottom 2px solid secColor


		+below(md)
			margin 15px 0

	.show-group.active
		color secColor
		border-bottom 2px solid secColor


.show-filters.active
	border-radius 5px
	box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)

.age-selector.active
	background-color secColor

	&:after,
	&:before
		height 0

	.age-icon
		svg
			fill vsColor

	.age-text
		color vsColor

.age-selector
	position relative
	display flex
	align-items center
	margin-top 10px
	padding 20px 13px
	border 2px solid secColor
	border-radius 10px
	background-color vsColor
	cursor pointer
	transition .2s

	&:before
		content ''
		position absolute
		top -2px
		right 8px
		width 75px
		height 2px
		background-color vsColor
		transition .2s

	&:after
		content ''
		position absolute
		bottom -2px
		left 60px
		width 44px
		height 2px
		background-color vsColor
		transition .2s

	.age-icon
		width 45px
		height 45px
		flex-shrink 0
		margin-right 13px

		svg
			width 100%
			height 100%
			fill defColor

	.age-text
		flex-shrink 0
		font normal rem(22px) ttFont, sans-serif
		line-height rem(20px)
		color defColor

		span
			display block
			margin-top 5px
			font normal rem(22px) stFont, sans-serif
			line-height rem(30px)

	&:hover
		background-color secColor

		&:after,
		&:before
			height 0

		.age-icon
			svg
				fill vsColor

		.age-text
			color vsColor


.product-flags
	position absolute
	top 0
	left 0
	z-index medLevel
	width 50%

	.flag-item
		display none
		position relative
		margin-top 8px
		padding 5px 20px
		font normal rem(16px) defFont, sans-serif
		line-height rem(20px)
		color vsColor
		background-color secColor

		&:before
			content ''
			position absolute
			top 0
			right -10px
			width 0
			height 0
			border-top 21px solid secColor
			border-right 14px solid transparent

		&:after
			content ''
			position absolute
			bottom 0
			right -10px
			width 0
			height 0
			border-bottom 21px solid secColor
			border-right 14px solid transparent


	.flag-item.popular
		background-color trdColor

		&:before
			border-top 21px solid trdColor

		&:after
			border-bottom 21px solid trdColor


	.flag-item.gift
		background-color atnColor

		&:before
			border-top 21px solid atnColor

		&:after
			border-bottom 21px solid atnColor


	.flag-item.discount
		background-color secColor

		&:before
			border-top 21px solid secColor

		&:after
			border-bottom 21px solid secColor

	.flag-item.show
		display inline-block

.product-flags.inner-flags
	width 30%
	top 33px

	.flag-item
		padding 2px 10px

.product-item
	position relative
	height 100%
	background-color vsColor
	border-radius 15px
	box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)
	transition .2s
	&.swiper-slide
		min-height 524px
		height inherit

	+below(xs)
		margin-bottom 30px

	.product-img
		display flex
		justify-content center
		align-items center
		height 300px
		border-radius 15px
		overflow hidden
		padding 15px
		img
			height auto
			width auto
			max-width 100%

	.product-info
		display flex
		flex-direction column
		height calc(100% - 300px)
		//min-height calc(524px - 258px)
		padding 10px 18px 35px 18px
		.product-name
			font normal rem(18px) stFont, sans-serif
			line-height rem(30px)
			color defColor
		.product-category
			.age-category
				margin-right 10px
				font normal rem(22px) ttFont, sans-serif
				line-height rem(20px)
				color defColor

			.weight-category
				font normal rem(22px) stFont, sans-serif
				line-height rem(20px)
				color defColor

		.old-price
			height 30px
			margin-top auto
			font normal rem(21px) stFont, sans-serif
			line-height rem(30px)
			color defColor
			text-decoration line-through
			text-decoration-color atnColor
			opacity .8

		.product-price
			margin 10px 0
			font normal rem(32px) stFont, sans-serif
			line-height rem(30px)
			color secColor

		.product-buy
			width 100%
			margin-top auto
			border 2px solid secColor
			border-radius 5px
			font normal rem(20px) ttFont, sans-serif
			line-height rem(44px)
			color defColor
			text-align center
			background-color vsColor
			cursor pointer
			transition .2s
			+below(xs)
				background-color secColor
				color vsColor

			&:focus
				outline none

	&:hover
		box-shadow 0 0 20px 0 rgba(0, 0, 0, .3)

		.product-info
			.product-buy
				background-color secColor
				color vsColor

.discount-item
	margin-bottom 30px
	border-radius 5px
	overflow hidden
	box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)
	transition .2s

	.discount-img
		min-height 354px
		border-bottom 1px solid brdColor

	.discount-info
		padding 16px 18px

		.discount-name
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor

		.discount-date
			margin-top 10px
			font normal rem(14px) defFont, sans-serif
			line-height rem(17px)
			color defColor
			opacity .8

	&:hover
		box-shadow 0 0 20px 0 rgba(0, 0, 0, .3)

.section-title
	margin 0
	font normal rem(52px) ttFont, sans-serif
	line-height rem(65px)
	color defColor
	+below(xs)
		font-size rem(36px)
		line-height rem(56px)

.section-subtitle
	font normal rem(24px) defFont, sans-serif
	line-height rem(30px)
	color defColor

//sections
.main-cover
	position relative
	height 868px
	background url("/assets/img/back.jpg") no-repeat center
	background-size cover

	+below(md)
		height 1069px
		background-position left

	+below(sm)
		height 970px
		padding-top 70px

	+below(xs)
		height 860px

	&:after
		content ''
		position absolute
		bottom -120px
		left 0
		width 100%
		height 167px
		background url('/assets/img/wave-cream.svg') no-repeat center

	.slide-container
		overflow hidden

	.main-slide
		height auto
		margin-top 15%
		+below(lg)
			margin-top 25%
		+below(md)
			margin-top 15%

		.main-info
			display flex
			flex-direction column
			justify-content center
			height 100%

			.info-label
				position relative
				display inline-block
				width 110px
				padding 10px 15px 10px 12px
				font normal rem(20px) defFont, sans-serif
				line-height rem(20px)
				color vsColor
				background-color secColor

				+below(xs)
					width 58px
					padding 5px 8px
					font-size rem(14px)


				&:before
					content ''
					position absolute
					top 0
					right -14px
					width 0
					height 0
					border-top 21px solid secColor
					border-right 14px solid transparent

				&:after
					content ''
					position absolute
					bottom 0
					right -14px
					width 0
					height 0
					border-bottom 21px solid secColor
					border-right 14px solid transparent

			.info-title
				margin-top 30px
				font normal rem(68px) ttFont, sans-serif
				line-height rem(95px)
				color defColor
				+below(xs)
					font normal rem(42px) ttFont, sans-serif

			.info-text
				font normal rem(18px) defFont, sans-serif
				line-height rem(26px)
				color defColor

			.info-link
				display flex
				justify-content center
				align-items center
				width 356px
				height 76px
				margin-top 33px
				border 1px solid trdColor
				border-radius 70px
				transition .4s

				span
					display flex
					align-items center
					padding 16px 60px
					font normal rem(22px) ttFont, sans-serif
					line-height rem(22px)
					color defColor
					border-radius 70px
					gradMxn(rgb(255, 168, 54), rgb(255, 204, 99))

					img
						flex-shrink 0
						margin-left 15px
						width 20px
						height 14px

				&:hover
					span
						img
							animation wiggle 1.5s linear infinite

				+below(sm)
					width 290px
					span
						padding 18px 28px

			.info-pagination
				margin-top 35px

				.swiper-pagination-bullet
					width 24px
					height 24px
					margin-right 10px
					background transparent
					border 6px solid brdColor
					border-radius 50%
					opacity 1

				.swiper-pagination-bullet-active
					position relative
					border 6px solid secColor
					animation rotate 5s linear infinite

					&:before
						content ''
						position absolute
						top -6px
						left 0
						width 4px
						height 15px
						transform rotate(-36deg)
						background bgColor

	.slider-product
		display flex
		flex-direction column
		width 250px

		.product-img
			height 330px
			img
				width auto
				height 100%

			+below(sm)
				height 240px

			+below(xs)
				height auto
				img
					width 100%
					height auto


		.product-title
			font 400 rem(18px) ttFont,sans-serif
			text-align center
			margin-top auto

		.product-oldprice
			font 400 rem(22px) ttFont,sans-serif
			text-align center
			text-decoration line-through
			text-decoration-color atnColor

		.product-discountprice
			font 400 rem(26px) ttFont,sans-serif
			text-align center
			color atnColor

.advantages
	margin-top 120px
	margin-bottom 15px
	+below(sm)
		margin-top 60px
	+below(xs)
		margin-top 30px

	.advatage-title
		position relative
		display inline-block
		font normal rem(26px) hdfFont, sans-serif
		line-height rem(26px)
		color defColor

		&:before
			content ''
			position absolute
			top -20px
			right -120px
			width 108px
			height 85px
			background url("/assets/img/icons/arrow-draw_.svg") no-repeat center
			background-size contain

			+below(xs)
				right -70px
				width 70px

		+below(sm)
			margin-bottom 15px

	.advantage-block
		margin-top 15px
		padding 20px 25px
		border-radius 15px
		border-top-right-radius 60px

		.advantage-icon
			height 50px

			img
				width auto
				height 100%

		.advantage-name
			margin-top 10px
			font normal rem(24px) ttFont, sans-serif
			line-height rem(30px)
			color vsColor

		.advantage-test
			font normal rem(16px) defFont, sans-serif
			line-height rem(22px)
			color vsColor

	.advantage-block.color-theme-1
		gradMxn(#23ad7b, #54D9A9)

	.advantage-block.color-theme-2
		gradMxn(#FFA836, #FFCC63)

	.advantage-block.color-theme-3
		gradMxn(#FF714F, #FF8E72)

.index-catalog
	margin-top 60px
	margin-bottom 15px
	overflow-x hidden

	.catalog-slider
		margin-top 30px
		+below(xs)
			.swiper-wrapper
				flex-wrap wrap

.slider-controls
	display flex
	margin-top 30px
	+below(xs)
		flex-wrap wrap
		margin 0

	.buttons
		display flex
		+below(xs)
			margin 15px 0 30px

		//.control-btn.swiper-button-disabled
		//	visibility hidden

		.control-btn
			flex-shrink 0
			width 52px
			height 52px
			margin-right 10px
			background-color trdColor
			background-repeat no-repeat
			background-size 20px 14px
			background-position center
			border-radius 50%
			transition .3s
			cursor pointer

			&:hover
				background-color secColor

		.green
			background-color secColor

			&:hover
				background-color trdColor

		.cert-prev,
		.catalog-prev,
		.discount-prev
			background-image url("/assets/img/icons/left-arrow-w.svg")

		.cert-next,
		.catalog-next,
		.discount-next
			background-image url("/assets/img/icons/right-arrow-w.svg")

	.link-btn
		width 253px
		height 52px
		margin-left 25px
		background-color trdColor
		border-radius 5px
		transition .3s
		+below(xs)
			width 100%
			margin 0

		span
			display block
			padding-right 30px
			font normal rem(20px) ttFont, sans-serif
			line-height rem(52px)
			color defColor
			text-align center
			background url("/assets/img/icons/right-arrow.svg") no-repeat center right 30px
			transition .3s

		&:hover
			background-color secColor

			span
				color vsColor
				background url("/assets/img/icons/right-arrow-w.svg") no-repeat center right 30px

	.green
		background-color secColor

		&:hover
			background-color trdColor

	.whiteText
		span
			color vsColor
			background url("/assets/img/icons/right-arrow-w.svg") no-repeat center right 30px

.slider-controls.show-controls
	flex-wrap wrap

	.buttons
		display flex
		margin-bottom 18px

.index-callback
	position relative
	display flex
	align-items center
	background url("/assets/img/callback-bg.svg") no-repeat center
	background-size cover
	+below(lg)
		padding 130px 0
		background-position inherit

	+below(md)
		padding 90px 0 0

	.callback-wrapper
		display flex
		flex-wrap wrap
		align-items center

		.callback-img
			width 520px
			margin-right -200px
			+below(1510px)
				width 330px
				margin-right 0
			+below(lg)
				width 520px

		.callback-info
			width 70%
			+below(lg)
				width 100%

			.callback-form
				display flex
				margin-top 30px
				z-index bsLevel
				+below(lg)
					flex-wrap wrap

				.callback-input
					width 253px
					height 52px
					margin-bottom 20px
					margin-right 20px
					padding 0 19px
					font normal rem(18px) defFont, sans-serif
					line-height rem(52px)
					color defColor
					background-color bgColor
					border 2px solid trdColor
					border-radius 5px

					&::-webkit-input-placeholder,
					&::-moz-placeholder,
					&:-ms-input-placeholder,
					&:-moz-placeholder,
					&::placeholder
						font normal rem(18px) defFont, sans-serif
						color defColor

					+below(sm)
						width 100%
						margin-right 0

				.callback-submit
					background url("/assets/img/icons/right-arrow.svg") no-repeat center right 60px trdColor
					margin 0
					padding 0
					padding-right 30px

					&::-webkit-input-placeholder,
					&::-moz-placeholder,
					&:-ms-input-placeholder,
					&:-moz-placeholder,
					&::placeholder
						font normal rem(20px) ttFont, sans-serif
						color defColor

.privacy-text
	max-width 435px
	margin-top 11px
	font normal rem(14px) defFont, sans-serif
	line-height rem(20px)
	color defColor
	opacity .8

	a
		display inline
		text-decoration underline

.discounts
	margin-top 30px
	margin-bottom 30px
	background url("/assets/img/discounts-bg.svg") no-repeat center

	.discounts-container
		overflow hidden

.index-seo
	padding 60px 0
	background url("/assets/img/seo-bg.svg") no-repeat center
	+below(xs)
		padding 30px 0

	.seo-text
		height auto

		h1
			font normal rem(28px) ttFont, sans-serif

		h1, h2, h3, h4, h5
			font-family ttFont, sans-serif
			color defColor
			line-height 1.5
			margin 0

		p
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor
			margin 15px 0


	.seo-text.closed
		position relative
		height 315px
		overflow hidden

	.seo-slider
		overflow hidden
		position relative
		+below(md)
			margin-top 60px
		+below(xs)
			margin-top 30px

		.seo-pagination
			position absolute
			left 0
			right 0
			bottom 25px
			display flex
			justify-content center
			z-index bsLevel

			.swiper-pagination-bullet
				width 18px
				height 18px
				background vsColor
				opacity 1

			.swiper-pagination-bullet-active
				background secColor


.footer
	position relative
	margin-top 75px
	padding 40px 0 75px
	background bgColor

	&:before
		content ''
		position absolute
		top -65px
		left 0
		width 100%
		height 75px
		background url("/assets/img/cream-wave-crop.svg") no-repeat center
		background-size cover

	.footer-container
		display flex
		justify-content space-between
		+below(sm)
			flex-wrap wrap

		.footer-contacts
			+below(sm)
				width 100%
				display flex
				flex-wrap wrap
				justify-content space-between

			.f-contact-line
				margin-bottom 30px
				padding-left 30px

				.f-contact-text
					font bold rem(18px) bdFont, sans-serif
					line-height rem(26px)
					color defColor

				.f-contact-name
					display inline-block
					font normal rem(16px) defFont, sans-serif
					line-height rem(20px)
					color defColor
					border-bottom 1px dashed defColor

				.map-icon
					position relative

					&:before
						content ''
						position absolute
						top 5px
						left -25px
						z-index bsLevel
						width 17px
						height 17px
						background url("/assets/img/icons/maps-black.svg") no-repeat center
						background-size contain

				.phone-icon
					position relative

					&:before
						content ''
						position absolute
						top 5px
						left -25px
						z-index bsLevel
						width 17px
						height 17px
						background url("/assets/img/icons/call-black.svg") no-repeat center
						background-size contain

		.footer-menu
			.footer-title
				font bolder rem(18px) bdFont, sans-serif
				line-height rem(32px)
				color defColor

			.footer-item
				font normal rem(16px) defFont, sans-serif
				line-height rem(32px)
				color defColor

			.before
				position relative

				&:before
					content ''
					position absolute
					bottom 0
					left 0
					height 2px
					width 0
					background defColor
					transition .3s

				&:hover
					&:before
						width 100%


		.footer-socials
			display flex
			margin-top 24px

			.f-social-link
				width 40px
				height 29px
				margin-right 15px
				transition .3s

				svg
					width 100%
					height 100%
					fill defFont

				&:hover
					transform scale(1.1)

					svg
						fill secColor

		.two-column-sm
			+above(sm)
				column-count 2
				column-gap 200px

.footer-copyrights
	position relative
	background-color secColor
	padding-top 25px
	padding-bottom 25px

	&:before
		content ''
		position absolute
		top -65px
		left 0
		width 100%
		height 75px
		background url("/assets/img/green-wave-crop.svg") no-repeat center
		background-size cover

	.copyrights-container
		display flex
		justify-content space-between
		+below(sm)
			flex-wrap wrap

		.copyright-text
			display flex
			align-items center
			font normal rem(14px) defFont, sans-serif
			line-height rem(18px)
			color vsColor
			+below(md)
				margin 5px 0

			a
				img

					margin-left 5px
					margin-top 4px

		.footer-privacy
			text-decoration underline

.modals-hangar
	display none

.modal-window
	position absolute
	top 0
	left 0
	display flex
	width 100%
	height 100%
	padding-top 10%
	flex-direction column
	justify-content center
	align-items center
	background bgColor

	.modal-title
		font normal rem(52px) ttFont, sans-serif
		line-height rem(56px)
		color defColor
		text-align center

	.modal-form
		.modal-input
			display block
			width 364px
			height 52px
			margin 22px auto 0
			padding-left 20px
			font normal rem(18px) defFont, sans-serif
			line-height rem(48px)
			color defColor
			background bgColor
			border 2px solid trdColor
			border-radius 5px

			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&:-moz-placeholder,
			&::placeholder
				font normal rem(18px) defFont, sans-serif
				line-height rem(48px)
				color defColor

		.modal-message
			height 120px
			padding 20px
			border-color brdColor
			line-height rem(32px)
			resize none

			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&:-moz-placeholder,
			&::placeholder
				padding 0
				line-height rem(32px)

		.modal-submit
			padding-right 30px
			background url("/assets/img/icons/right-arrow.svg") no-repeat center right 100px trdColor

	.modal-privacy
		margin-top 15px
		font normal rem(14px) defFont, sans-serif
		line-height rem(20px)
		color defColor
		text-align center
		opacity .7

		a
			display inline
			text-decoration underline

	.modal-controls
		position absolute
		top 40px
		right 40px
		cursor pointer

		svg
			display block
			width 40px
			height 40px
			margin auto
			fill secColor
			transition .3s

		.control-text
			margin-top 3px
			font normal rem(14px) defFont, sans-serif
			line-height rem(20px)
			color defColor
			opacity .7

		&:hover
			svg
				fill trdColor
				transform scale(1.1)


	+below(xs)
		padding 90px 15px 30px
		.modal-title
			font-size rem(46px)
			line-height rem(52px)

		.modal-controls
			top 30px
			right 15px

			svg
				width 25px
				height 25px

		.modal-form
			.modal-input
				width 100%

			.modal-submit
				padding-right: 50px;
				background: url(/assets/img/icons/right-arrow.svg) no-repeat center right 70px #ffb142;

.inner-page
	margin-bottom 145px
	padding-top 220px
	+below(md)
		padding-top 130px
	+below(sm)
		margin-bottom 30px
	+below(xs)
		padding-top 90px

	.section-title
		margin-bottom 30px

.filter-toggle
	width 100%
	height 56px
	background-color trdColor
	border-radius 5px
	border none

	&:focus
		outline none

	.btn-text
		display block
		width 100%
		height 100%
		font normal rem(20px) ttFont, sans-serif
		line-height rem(56px)
		color defColor
		background url("/assets/img/icons/settings.svg") no-repeat center right 35px

		&:last-child
			display none

	+above(lg)
		display none

.filter-placeholder
	display none
	opacity 0

.filter-bar
	//padding 15px
	border-radius 5px
	box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)
	transition .3s

	+above(lg)
		margin-top -115px

	+below(md)
		margin 15px 0
		box-shadow none

	.filter-form
		padding 15px
		+below(md)
			display none

	.filter-title
		display flex
		justify-content center
		align-items center
		width 100%
		height 52px
		margin-top 20px
		font normal rem(18px) / 50px defFont, sans-serif
		line-height rem(26px)
		text-align center
		color vsColor
		border 2px solid secColor
		border-radius 5px
		background-color secColor

		+below(md)
			display none

	.catalog-filter
		.filter-group
			padding 0
			margin-top 15px
			margin-bottom 28px

			.group-name
				font bold rem(18px) bdFont, sans-serif
				line-height rem(26px)
				color defColor

			.filter-line
				display flex
				margin-top 10px

				.input-label
					margin-right 8px
					font normal rem(18px) defFont, sans-serif
					line-height rem(33px)
					color defColor

				.filter-price
					width 78px
					height 33px
					padding-left 8px
					font normal rem(14px) defFont, sans-serif
					line-height rem(33px)
					color defColor
					border 1px solid brdColor
					border-radius 5px

					&:nth-child(2)
						margin-right 8px


				+below(lg)
					flex-wrap wrap
					.filter-price
						width auto
				+below(md)
					.filter-price
						width 78px

				.checkbox-input
					width auto

				.checkbox-label
					position relative
					padding-left 30px
					font normal rem(14px) defFont, sans-serif
					line-height rem(24px)
					color defColor
					cursor pointer

					&:before
						content ''
						position absolute
						top 0
						left 0
						width 24px
						height 24px
						border 1px solid brdColor
						border-radius 5px
						background url("/assets/img/icons/checkbox.svg") no-repeat center
						background-size contain

				.checkbox-input
					display none

				.checkbox-input:checked ~ .checkbox-label:before
					background url("/assets/img/icons/checkbox-checked.svg") no-repeat center
					background-size contain

				.vendor-select
					width 100%
					height 44px
					padding-left 10px
					font normal rem(14px) defFont, sans-serif
					line-height rem(40px)
					color defColor
					background url("/assets/img/icons/dropdown-black.svg") no-repeat center right 13px
					border 2px solid trdColor
					border-radius 5px
					appearance none

				.filter-results
					width 100%
					height 44px
					font normal rem(20px) stFont, sans-serif
					color vsColor
					text-align center
					background #536377
					border-radius 5px
					border 2px solid #536377
					cursor pointer

				.filter-close
					display none
					width 100%
					height 44px
					font normal rem(20px) stFont, sans-serif
					line-height rem(40px)
					color vsColor
					text-align center
					margin-top 15px
					background secColor
					border-radius 5px
					border 2px solid secColor
					cursor pointer
					+below(md)
						display block


.filter-bar.active
	box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)

.breadcrumbs
	opacity .8

	.crumb-item
		display inline
		padding-left 10px
		font normal rem(14px) defFont, sans-serif
		line-height rem(20px)
		color defColor

		&:first-child
			padding-left 0


.page-seo
	margin-top 60px

	.seo-point
		margin-bottom 15px

		.point-icon
			width 67px
			height 67px
			margin-bottom 11px

			img
				width auto
				height 100%

		.point-title
			font bold rem(20px) bdFont, sans-serif
			line-height rem(24px)
			color defColor

		.point-text
			font normal rem(16px) defFont, sans-serif
			line-height rem(26px)
			color defColor

	.page-text
		margin-top 25px
		font normal rem(18px) defFont, sans-serif
		line-height rem(26px)
		color defColor
		opacity .8

.product-slider
	position relative
	width 100%
	padding 30px 0
	border 1px solid brdColor
	border-radius 5px
	overflow hidden

	.image-slide
		cursor zoom-in

		&:before
			content ''
			position absolute
			top 15px
			right 15px
			z-index bsLevel
			width 44px
			height 44px
			border-radius 50%
			background url("/assets/img/icons/search.svg") no-repeat center secColor
			background-size 23px
			opacity 0
			transition .3s

		&:hover
			&:before
				opacity 1

.product-thumbs
	margin-top 20px
	margin-bottom 20px
	overflow hidden

	.thumb-slide
		height 120px
		display flex
		justify-content center
		align-items center
		border 1px solid brdColor
		border-radius 5px
		overflow hidden
		cursor pointer

.share-socials
	display flex
	align-items center
	margin 40px 0
	font bold rem(18px) bdFont, sans-serif
	line-height rem(22px)
	color defColor

	.share-wrapper
		display flex
		width 100%
		margin-left 13px
		+below(sm)
			margin-top 10px

		.share-btn
			width 27px
			height 27px
			border-radius 3px
			overflow hidden
			margin-right 5px

	+below(sm)
		margin 20px 0

.product-form
	padding 20px
	padding-bottom 46px
	background url("/assets/img/form-bg.svg") no-repeat center bgColor
	background-size cover

	.form-title
		font normal rem(42px) ttFont, sans-serif
		line-height rem(56px)
		color defColor

	.form-text
		font normal rem(16px) defFont, sans-serif
		line-height rem(26px)
		color defColor

	.form-inputs
		.input-style
			width 253px
			height 52px
			margin-top 17px
			padding-left 23px
			background-color transparent
			border 2px solid trdColor
			border-radius 5px
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor

			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&:-moz-placeholder,
			&::placeholder
				font normal rem(18px) defFont, sans-serif
				line-height rem(26px)
				color defColor

		.send-btn
			padding 0
			padding-right 60px
			background url("/assets/img/icons/right-arrow.svg") no-repeat center right 30px trdColor

		.privacy-text
			margin-top 15px
			font normal rem(14px) defFont, sans-serif
			line-height rem(20px)
			color defColor
			opacity .8

			a
				display inline
				text-decoration underline

.hotline-block
	margin 35px 0
	padding-left 45px
	font normal rem(18px) defFont, sans-serif
	line-height rem(26px)
	color defColor
	background url("/assets/img/icons/question-mark.svg") no-repeat top left

	a
		display block
		font bold rem(18px) bdFont, sans-serif
		line-height rem(26px)
		color defColor

.product-page-info
	margin-bottom 30px
	border 1px solid brdColor
	border-radius 6px

	.info-section
		padding 0 25px 35px
		border-radius 6px
		border 1px solid brdColor
		+below(xs)
			padding 0 15px 15px

	.product-characteristics
		overflow hidden
		height auto

	.product-characteristics.closed
		height 250px

	.show-chr-btn
		margin-top 30px
		font normal rem(16px) defFont, sans-serif
		line-height rem(19px)
		color defColor
		background none
		border none
		border-bottom 2px dashed brdColor

		&:focus
			outline none


	.buy-btn
		display block
		width 157px
		height 44px
		margin-left 30px
		font normal rem(20px) stFont, sans-serif
		line-height rem(44px)
		color vsColor
		text-align center
		background-color secColor
		border none
		border-radius 5px

		&:focus
			outline none
		+below(xs)
			width 100%
			margin 0
			margin-top 15px

	.info-line
		display flex
		align-items center
		margin-top 30px
		+below(sm)
			margin-top 15px
			justify-content space-between

		.info-title
			font normal rem(16px) defFont, sans-serif
			line-height rem(19px)
			color defColor

		.info-old-price
			margin-left 10px
			font normal rem(21px) stFont, sans-serif
			line-height rem(30px)
			color defColor
			text-decoration line-through
			text-decoration-color atnColor
			+below(xs)
				flex-shrink 0
				margin 0

		.info-price
			margin-left 10px
			font normal rem(32px) stFont, sans-serif
			line-height rem(32px)
			color secColor
			+below(xs)
				flex-shrink 0
				margin 0

		+below(xs)
			flex-wrap wrap
			justify-content space-between

		.dots
			height 11px
			flex-grow 1
			margin-left 4px
			background url("/assets/img/icons/dot.svg") repeat-x bottom
			background-size 7px
			+below(xs)
				display none


	.info-line.no-wrap
		flex-wrap nowrap

	.info-line.zero-margin
		margin 0

	.color-picks
		margin-top 45px
		+below(xs)
			margin-top 0

		.color-title
			font bold rem(18px) bdFont, sans-serif
			line-height rem(22px)
			color defColor

		.colors-list
			display flex
			flex-wrap wrap
			margin-top 10px
			+below(xs)
				width auto

			.color-block
				width 96px
				margin-right 15px
				+below(xs)
					width auto

				.color-circle
					position relative
					display block
					width 52px
					height 52px
					margin 0 auto
					margin-bottom 8px
					border-radius 50%
					cursor pointer

					&:before
						content ''
						position absolute
						top 0
						left 0
						right 0
						bottom 0
						z-index bsLevel
						width 100%
						height 100%
						background url("/assets/img/icons/checked.svg") no-repeat center
						background-size 26px 20px
						opacity 0
						transition .2s

					+below(xs)
						width 40px
						height 40px

				.color-radio
					display none

				.color-radio:checked ~ .color-circle:before
					opacity 1

				.color-name
					margin-top 10px
					font normal rem(14px) defFont, sans-serif
					line-height rem(17px)
					color defColor
					text-align center
					opacity .7
					+below(xs)
						display none

	.page-seo
		display flex
		justify-content space-between
		padding 30px
		margin 0

		.seo-point
			flex-shrink .2
			margin-right 50px

		+below(lg)
			flex-wrap wrap
			.seo-point
				width 45%
				margin 0
				margin-bottom 30px

		+below(sm)
			.seo-point
				width 100%

	.product-description.closed
		overflow hidden
		height 355px

	.product-description
		padding-top 15px

		h2
			margin 0
			font bold rem(16px) bdFont, sans-serif
			line-height rem(26px)
			color defColor

		p
			margin 0
			font normal rem(16px) defFont, sans-serif
			line-height rem(26px)
			color defColor

		table
			border-spacing 7px 5px
			border-collapse separate

.product-video
	display flex
	align-items center
	margin-top 15px
	font normal rem(16px) defFont, sans-serif
	line-height rem(19px)
	color defColor

	span
		border-bottom 1px dashed transparent
		transition .3s 0s

	svg
		margin-right 15px
		fill red
		size 30px
		opacity .7
		transition .3s 0s

	&:hover
		span
			border-bottom 1px dashed defColor

		svg
			fill red
			opacity 1

.rating
	display flex
	margin-left 15px

	.star-item
		width 18px
		height 18px
		margin-right 5px
		margin-bottom 5px
		fill dbrdColor

	.filled
		fill secColor

	.rate-number
		margin 0 15px
		font bold rem(26px) bdFont, sans-serif
		line-height rem(16px)
		color defColor

.other-products
	overflow hidden

	.others-title
		margin-bottom 15px
		font normal rem(28px) ttFont, sans-serif
		line-height rem(36px)
		color defColor

.information
	font normal rem(18px) defFont, sans-serif
	line-height rem(26px)
	color defColor

	h2
		margin-top 30px
		font normal rem(28px) ttFont, sans-serif
		line-height rem(36px)
		color defColor

	img
		display inline
		width auto
		margin-left 10px

.serificates-section
	overflow hidden

	+below(md)
		padding-bottom 30px

	.serificates-slider
		overflow hidden

		.cert-item
			position relative
			transition .3s

			&:before
				content ''
				position absolute
				top 15px
				left 15px
				width 44px
				height 44px
				border-radius 50%
				background url("/assets/img/icons/search.svg") no-repeat center secColor
				background-size 22px
				transition .3s
				opacity 0

			&:hover
				box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)

				&:before
					opacity 1

.brands-section
	margin-top 30px

	.brend-item
		margin-bottom 25px
		border 1px solid brdColor
		border-radius 5px

		.brand-text
			padding 0 11px
			padding-bottom 11px
			font bold rem(16px) bdFont, sans-serif
			line-height rem(22px)
			color defColors

.delivery-text
	font normal rem(18px) defFont, sans-serif
	line-height rem(26px)
	color defColor

	h2
		font normal rem(28px) ttFont, sans-serif
		line-height rem(36px)
		color defColor

	img
		display inline
		width auto

	b
		font bold rem(18px) bdFont, sans-serif
		line-height rem(26px)
		color defColor

.reviews
	.review-block
		padding 30px 25px
		border-radius 5px
		box-shadow 0 0 20px 0 rgba(0, 0, 0, .15)
		margin-bottom 20px

		.review-author
			margin-right 15px
			font bold rem(18px) bdFont, sans-serif
			line-height rem(26px)
			color defColor

		.review-date
			font normal rem(14px) defFont, sans-serif
			line-height rem(20px)
			color defColor
			opacity .7

		.review-text
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor

		.review-gallery
			display flex
			margin-top 15px

			+below(sm)
				flex-wrap wrap

			.big-image
				width 48.3%
				flex-shrink 0
				margin-right 20px
				+below(sm)
					display none

			.small-images
				display flex
				flex-wrap wrap
				justify-content space-between

				.review-image
					width 48%
					margin-bottom 15px

					+below(xs)
						width 46%
						&:nth-child(2n)
							margin-right 0

					&:first-child
						+above(md)
							display none

		.review-video
			margin-top 15px

			iframe
				width 100%

		.review-title
			font normal rem(28px) ttFont, sans-serif
			line-height rem(36px)
			color defColor

		.leave-review
			.review-input
				display block
				width 100%
				height 53px
				padding 17px
				margin-bottom 11px
				font normal rem(14px) defFont, sans-serif
				line-height rem(20px)
				color defColor
				background transparent
				border 2px solid brdColor
				border-radius 5px
				opacity .7

				&::-webkit-input-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder,
				&:-moz-placeholder,
				&::placeholder
					font normal rem(14px) defFont, sans-serif
					line-height rem(20px)
					color defColor

			.review-textarea
				height 163px
				resize none

			.review-files
				display none

			.review-files-label
				width 52px
				height 52px
				margin 0
				margin-right 20px
				background url("/assets/img/icons/clip.svg") no-repeat center
				background-size 29px 26px
				border 2px dashed brdColor
				border-radius 5px
				cursor pointer

			.review-send
				width 80%
				height 52px
				padding-right 15px
				font normal rem(20px) stFont, sans-serif
				line-height rem(52px)
				color defColor
				background url("/assets/img/icons/right-arrow.svg") no-repeat center right 85px trdColor
				border none
				border-radius 5px

				+below(sm)
					background trdColor

			.input-line
				display flex
				justify-content center
				align-items center

			.showHere
				display flex
				flex-wrap wrap
				margin-top 12px

				.attach-file
					position relative
					width 52px
					height 52px
					margin-right 10px
					border 1px solid brdColor
					border-radius 5px
					overflow hidden
					cursor pointer

					img
						width auto
						height 100%

					.attach-file-delete
						display none

.faq-page
	.faq-block
		margin-bottom 16px
		border 1px solid brdColor
		border-radius 5px
		cursor pointer

		.question
			position relative
			padding 30px
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor
			background bgColor
			border-radius 5px

			&:before
				content ''
				position absolute
				top 40px
				right 25px
				width 22px
				height 12px
				background url("/assets/img/icons/arrow_down.svg") no-repeat center
				background-size contain
				transition .3s

			+below(sm)
				padding 20px
				padding-right 50px

		.answer
			display none
			padding 30px
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor

	.faq-block.active
		.question:before
			transform rotate(180deg)

.contacts-page
	column-count 2

	.contact-block
		padding-left 30px
		margin-bottom 50px

		.contact-title
			margin-bottom 5px
			font bold rem(20px) bdFont, sans-serif
			line-height rem(24px)
			color defColor

		.contact-text
			min-height 44px
			font normal rem(18px) defFont, sans-serif
			line-height rem(26px)
			color defColor

	+below(sm)
		column-count 1

		.contact-block
			margin-bottom 15px

			.contact-text
				min-height auto

	.address
		background url("/assets/img/icons/maps-and-flags.svg") no-repeat 5px 5px

	.work-hours
		background url("/assets/img/icons/clock-circular-outline.svg") no-repeat 5px 5px

	.phone
		background url("/assets/img/icons/call-answer.svg") no-repeat 5px 5px

	.email
		background url("/assets/img/icons/mail-black-envelope-symbol.svg") no-repeat 5px 5px

.contacts-info
	margin-top 35px
	margin-bottom 135px
	+below(sm)
		margin-bottom 30px
		overflow-x scroll

	.company-names
		margin-bottom 35px
		column-count 3

		.name-block
			.name-title
				font bold rem(20px) bdFont, sans-serif
				line-height rem(24px)
				color defColor

			.name-text
				font normal rem(18px) defFont, sans-serif
				line-height rem(26px)
				color defColor

		+below(sm)
			column-count 1
			.name-block
				margin-bottom 15px

	table
		width 100%

		tr
			td
				height 44px
				min-width 210px

.about-page
	.page-seo
		display flex
		justify-content space-between
		margin 50px 0
		+below(sm)
			flex-wrap wrap

	.about-slider
		overflow hidden
		margin-bottom 30px

		.about-slide
			cursor zoom-in

			&:before
				content ''
				position absolute
				top 15px
				right 15px
				z-index bsLevel
				width 44px
				height 44px
				border-radius 50%
				background url("/assets/img/icons/search.svg") no-repeat center secColor
				background-size 23px
				opacity 0
				transition .3s

			&:hover
				&:before
					opacity 1


.about-title
	margin-bottom 30px
	font normal rem(28px) ttFont, sans-serif
	line-height rem(36px)
	color defColor

.tv-reports
	.report-block
		position relative
		margin-bottom 30px

		&:before
			content ''
			position absolute
			top 0
			left 0
			z-index medLevels
			width 100%
			height 100%
			background url("/assets/img/icons/play-button.svg") no-repeat center
			background-size 79px
			transition .3s

		&:hover
			&:before
				background-size 120px

	.cover-wrap
		max-height 312px
		overflow hidden

		img
			width 100%
			margin-bottom 0

.error-page
	display flex
	flex-direction column
	justify-content center
	align-items center
	min-height 500px
	+below(sm)
		min-height 300px

	.error-img
		max-width 500px

	.error-title
		margin 27px 0 15px
		font bold rem(20px) bdFont, sans-serif
		line-height rem(24px)
		color defColor
		text-align center

	.error-text
		font normal rem(18px) defFont, sans-serif
		line-height rem(26px)
		color defColors
		text-align center

		a
			display inline
			text-decoration underline

.oamtc-page
	margin-bottom 15px

	p
		margin 15px 0

	img
		display inline
		width auto
		margin-bottom 30px
		max-width 100%

.tv-reports
	margin-bottom 0

	p
		margin-top 15px

.oficial-links
	.links-title
		font bold rem(18px) bdFont, sans-serif
		line-height rem(26px)
		color defColors

	a
		display inline
		margin-left 5px


.adac-page
	font-size rem(18px)

	img
		max-width 100%
		height auto

	.page-links
		display flex
		flex-wrap wrap
		max-width 315px
		margin-top 30px
		padding 30px 35px
		background-color bgColor
		border-radius 5px

		.go-to-link
			margin-bottom 25px
			border-bottom 1px solid defColor

	.adac-rates
		margin-top 30px

		.rate-symbols
			display flex
			flex-wrap wrap
			margin-top 30px
			margin-bottom 65px
			+below(sm)
				margin-bottom 30px

			.symbol-block
				display flex
				align-items center
				margin-right 30px
				margin-bottom 15px

				.symbol-img
					width 22px
					height 22px

				.symbol-text
					margin-left 10px
					line-height rem(22px)

		.sorting-directions
			display flex
			flex-wrap wrap
			height 100%
			align-content center

			span
				display block
				width 100%

			.sort-type
				margin-top 10px
				margin-right 35px
				font normal rem(18px) defFont, sans-serif
				line-height rem(26px)
				color defColor
				border-bottom 1px dashed defColor
				transition .3s
				cursor pointer

				&:hover
					color secColor
					border-color secColor

			.sort-type.active
				font bold rem(18px) bdFont, sans-serif
				line-height rem(26px)
				color secColor
				border-bottom 1px solid secColor

		.results-list
			margin-top 30px

			.product-item.horizontal
				display flex
				min-height auto
				height auto
				padding 15px 18px
				margin-bottom 30px

				.product-img
					height auto
					width 130px
					flex-shrink 0
					margin-right 15px
					+below(md)
						width 160px
					+below(sm)
						width 200px
					+below(xs)
						width 270px
						max-width 100%

				.product-info
					display flex
					flex-wrap wrap
					flex-direction column
					justify-content space-around
					width 100%
					height auto
					min-height auto
					padding 0

					.product-name
						width 100%
						font-family stFont, sans-serif
						font-size rem(24px)
						line-height 48px
						font-weight 700

					.rate-symbols
						height 23px
						margin 0

						+below(md)
							margin-top 15px

						+below(xs)
							height auto

						.symbol-block
							margin-right 15px

							.symbol-text
								margin 0
								margin-right 10px
								font-size rem(14px)

							.symbol-img
								width 15px
								height 15px

				.product-prices
					.prices-line
						display flex
						justify-content space-between
						align-items center
						width 100%

						+below(md)
							flex-wrap wrap
							margin-bottom 15px

						+below(xs)
							width 100%

						.old-price
							margin 0
							line-height rem(30px)

						.product-price
							margin 0
							line-height rem(30px)

						.product-buy
							width 117px
							margin 0

				.info-line
					display flex
					flex-wrap wrap

					.rate-number
						margin 0 15px
						font bold rem(26px) bdFont, sans-serif
						line-height rem(16px)
						color defColor

				+below(lg)
					flex-wrap wrap

		.load-more
			width 203px
			height 52px
			font normal rem(20px) stFont, sans-serif
			line-height rem(52px)
			color defColors
			text-align center
			background trdColor
			border none
			border-radius 5px

.testing
	margin-top 30px

.desktop
	+below(sm)
		display none !important

.mobile
	display none
	+below(sm)
		display block

.info-line.mobile
	display none
	+below(sm)
		display flex !important

.map-block
	height 500px