@-moz-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'BloggerSans-Medium';
  src: url("../fonts/bloggersans/BloggerSans-Medium.eot");
  src: url("../fonts/bloggersans/BloggerSans-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/bloggersans/BloggerSans-Medium.woff") format('woff'), url("../fonts/bloggersans/BloggerSans-Medium.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BloggerSans-Regular';
  src: url("../fonts/bloggersans/Bloggersans.eot");
  src: url("../fonts/bloggersans/Bloggersans.eot?#iefix") format('embedded-opentype'), url("../fonts/bloggersans/Bloggersans.woff") format('woff'), url("../fonts/bloggersans/Bloggersans.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Regular';
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato/Lato-Regular.woff") format('woff'), url("../fonts/Lato/Lato-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Bold';
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/Lato/Lato-Bold.woff") format('woff'), url("../fonts/Lato/Lato-Bold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BadScript-Regular';
  src: url("../fonts/Bad_Script/BadScript-Regular.eot");
  src: url("../fonts/Bad_Script/BadScript-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Bad_Script/BadScript-Regular.woff") format('woff'), url("../fonts/Bad_Script/BadScript-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url("../fonts/Open_Sans/OpenSans-Light.eot");
  src: url("../fonts/Open_Sans/OpenSans-Light.eot?#iefix") format('embedded-opentype'), url("../fonts/Open_Sans/OpenSans-Light.woff") format('woff'), url("../fonts/Open_Sans/OpenSans-Light.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*
Author: Булатасов Вадим
Name project: Детские кресла
*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
* img {
  display: block;
  width: 100%;
  height: auto;
}
a {
  display: block;
  text-decoration: none;
  color: #26303d;
}
a:hover {
  color: #26303d;
  text-decoration: none;
}
ol,
ul {
  margin-left: 30px;
}
textarea:focus,
input:focus {
  outline: none;
}
html {
  font-size: 16px;
}
body {
  line-height: 1.25rem;
  font-family: Lato-Regular;
  color: #26303d;
  size: 100%;
  overflow-x: hidden;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 142px;
  background-color: #23ad7b;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
}
@media only screen and (max-width: 1200px) {
  .header:before {
    border-radius: 0;
  }
}
@media only screen and (max-width: 992px) {
  .header:before {
    display: none;
  }
}
.header .header-container .top-menu {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 25px;
  transition: 0.2s 0s ease;
}
@media only screen and (max-width: 992px) {
  .header .header-container .top-menu {
    position: absolute;
    top: 98px;
    left: 0;
    display: block;
    width: 100%;
    background-color: #23ad7b;
    padding: 0 15px;
    transform: translate(0%, -100%);
    will-change: transform;
  }
}
@media only screen and (max-width: 768px) {
  .header .header-container .top-menu {
    top: 70px;
  }
}
.header .header-container .top-menu .search-block {
  width: 195px;
  height: 32px;
  padding-right: 30px;
  font: normal 1rem OpenSans-Light, sans-serif;
  color: #fff;
  background: url("/assets/img/icons/search.svg") no-repeat center right 3px;
  background-size: 23px;
  border: none;
  border-bottom: 1px solid #fff;
}
@media only screen and (max-width: 992px) {
  .header .header-container .top-menu .search-block {
    margin: 25px 0;
  }
}
@media only screen and (max-width: 768px) {
  .header .header-container .top-menu .search-block {
    width: 100%;
  }
}
.header .header-container .top-menu .search-block::-webkit-input-placeholder {
  font: normal 1rem OpenSans-Light, sans-serif;
  color: #fff;
}
.header .header-container .top-menu .top-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .header .header-container .top-menu .top-links {
    padding: 0 15px;
  }
  .header .header-container .top-menu .top-links .top-link-item {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 992px) {
  .header .header-container .top-menu .top-links {
    display: none;
  }
}
.header .header-container .top-menu .top-link-item {
  position: relative;
  margin-right: 35px;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.375rem;
  color: #fff;
}
.header .header-container .top-menu .top-link-item:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 2px;
  width: 0;
  background: #fff;
  transition: 0.2s 0s ease;
}
.header .header-container .top-menu .top-link-item:hover:after {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .header .header-container .top-menu .mobile-links {
    display: none;
  }
}
.header .header-container .top-menu .mobile-links .top-link-item {
  margin: 13px 0;
}
.header .header-container .top-menu .mobile-links .top-link-item:first-child {
  margin-top: 0;
}
.header .header-container .top-menu .top-address {
  padding-left: 25px;
}
.header .header-container .top-menu .top-address .address-text {
  position: relative;
  font: bold 1.125rem/1.5 Lato-Bold, sans-serif;
  color: #fff;
}
.header .header-container .top-menu .top-address .address-text:before {
  content: '';
  position: absolute;
  top: 5px;
  left: -25px;
  width: 15px;
  height: 20px;
  background: url("/assets/img/icons/map.svg") no-repeat center;
  background-size: contain;
}
.header .header-container .top-menu .top-address .address-link {
  position: relative;
  display: inline;
  font: normal 1rem/1.5 Lato-Regular, sans-serif;
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .header .header-container .top-menu .top-address .address-link {
    display: none;
  }
}
.header .header-container .top-menu .top-address .address-link:before {
  content: '';
  position: absolute;
  bottom: -3px;
  height: 0;
  width: 100%;
  border-bottom: 1px dashed #e5e5e5;
}
.header .header-container .top-menu .top-address .address-link:hover:before {
  border-bottom: 1px solid #e5e5e5;
}
.header .header-container .top-menu .mobile-phone {
  flex-shrink: 0;
  padding-left: 30px;
  margin: 30px 0;
}
@media only screen and (min-width: 992px) {
  .header .header-container .top-menu .mobile-phone {
    display: none;
  }
}
.header .header-container .top-menu .mobile-phone .phone-text {
  position: relative;
  font: bolr 1.125rem/1.5 Lato-Bold, sans-serif;
  color: #fff;
}
.header .header-container .top-menu .mobile-phone .phone-text:before {
  content: '';
  position: absolute;
  top: 5px;
  left: -30px;
  width: 25px;
  height: 20px;
  background: url("/assets/img/icons/phone-white.svg") no-repeat center;
  background-size: contain;
}
.header .header-container .top-menu .mobile-phone .call-link {
  position: relative;
  display: inline;
  font: normal 1rem/1.5 Lato-Regular, sans-serif;
  color: #fff;
}
.header .header-container .top-menu .mobile-phone .call-link:before {
  content: '';
  position: absolute;
  bottom: -3px;
  height: 0;
  width: 100%;
  border-bottom: 1px dashed #fff;
}
.header .header-container .top-menu .mobile-phone .call-link:hover:before {
  border-bottom: 1px solid #fff;
}
.header .header-container .top-menu .mobile-socials {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 39px;
}
@media only screen and (min-width: 992px) {
  .header .header-container .top-menu .mobile-socials {
    display: none;
  }
}
.header .header-container .top-menu .mobile-socials .social-link {
  height: 29px;
  margin-right: 25px;
}
.header .header-container .top-menu .mobile-socials .social-link img {
  width: auto;
  height: 100%;
}
.header .header-container .top-menu.slide {
  transform: translate(0%, 0%);
}
.header .header-container .main-menu {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -48px;
  margin-left: -48px;
  padding-right: 48px;
  padding-left: 48px;
  height: 98px;
  background: #fff4e8;
  border-radius: 50px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}
@media only screen and (max-width: 1200px) {
  .header .header-container .main-menu {
    margin: 0;
  }
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu {
    position: absolute;
    left: 0;
    width: 100%;
    height: 98px;
    border-radius: 0;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 768px) {
  .header .header-container .main-menu {
    height: 70px;
  }
}
.header .header-container .main-menu .logo-block {
  flex-shrink: 0;
  max-height: 63px;
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .logo-block {
    flex-grow: 1;
  }
}
.header .header-container .main-menu .logo-block img {
  width: auto;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .header .header-container .main-menu .logo-block {
    height: 45px;
  }
}
.header .header-container .main-menu .main-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .header .header-container .main-menu .main-links {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-links {
    display: none;
  }
}
.header .header-container .main-menu .main-links .main-link-item {
  position: relative;
  margin-right: 35px;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.375rem;
  color: #26303d;
}
.header .header-container .main-menu .main-links .main-link-item:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 2px;
  width: 0;
  background: #26303d;
  transition: 0.2s 0s ease;
}
.header .header-container .main-menu .main-links .main-link-item:hover:after {
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .header .header-container .main-menu .main-links .main-link-item {
    margin: 5px 35px;
  }
}
.header .header-container .main-menu .main-phone {
  flex-shrink: 0;
  width: 210px;
  padding-left: 30px;
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-phone {
    width: auto;
    padding-left: 0;
  }
}
.header .header-container .main-menu .main-phone .phone-text {
  position: relative;
  font: bold 1.125rem/1.5 Lato-Bold, sans-serif;
  color: #26303d;
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-phone .phone-text {
    display: none;
  }
}
.header .header-container .main-menu .main-phone .phone-text:before {
  content: '';
  position: absolute;
  top: 5px;
  left: -30px;
  width: 25px;
  height: 20px;
  background: url("/assets/img/icons/phone-green.svg") no-repeat center;
  background-size: contain;
}
.header .header-container .main-menu .main-phone .call-link {
  position: relative;
  display: inline;
  flex-shrink: 0;
  font: normal 1rem/1.5 Lato-Regular, sans-serif;
  color: #26303d;
}
.header .header-container .main-menu .main-phone .call-link:before {
  content: '';
  position: absolute;
  bottom: -3px;
  height: 0;
  width: 100%;
  border-bottom: 1px dashed #26303d;
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-phone .call-link:before {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-phone .call-link {
    display: block;
    width: 35px;
    height: 35px;
    margin-right: 29px;
    background: url("/assets/img/icons/phone-green.svg") no-repeat center;
    background-size: contain;
  }
}
@media only screen and (max-width: 768px) {
  .header .header-container .main-menu .main-phone .call-link {
    width: 24px;
    height: 24px;
  }
}
.header .header-container .main-menu .main-phone .call-link:hover:before {
  border-bottom: 1px solid #26303d;
}
@media only screen and (max-width: 992px) {
  .header .header-container .main-menu .main-phone .call-link span {
    display: none;
  }
}
.header .header-container .main-menu .mobile-ctrl {
  width: 40px;
  height: 40px;
  background: url("/assets/img/icons/burger.svg") no-repeat center;
  background-size: contain;
  opacity: 1;
  transition: 0.1s 0s ease;
}
@media only screen and (min-width: 992px) {
  .header .header-container .main-menu .mobile-ctrl {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .header .header-container .main-menu .mobile-ctrl {
    width: 30px;
    height: 30px;
  }
}
.header .header-container .main-menu .mobile-ctrl.close {
  background: url("/assets/img/icons/cross.svg") no-repeat center;
  background-size: contain;
}
.menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 46px;
  border: 1px solid #ffb142;
  border-radius: 70px;
  transition: 0.4s 0s ease;
}
.menu-link span {
  display: flex;
  align-items: center;
  padding: 6px 20px;
  font: normal 1rem BloggerSans-Medium, sans-serif;
  line-height: 1.375rem;
  color: #26303d;
  border-radius: 70px;
  background: #ffa836;
  background: -moz-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -webkit-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -o-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -ms-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: linear-gradient(0deg, #ffa836 30%, #ffcc63 70%);
  transition: 0.8s 0s ease;
}
.menu-link span:hover {
  background: #ffcc63;
  background: -moz-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -webkit-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -o-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -ms-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: linear-gradient(0deg, #ffa836 0%, #ffcc63 100%);
}
.menu-link span img {
  flex-shrink: 0;
  margin-left: 15px;
  width: 20px;
  height: 14px;
}
.slide-btn-wrapper {
  display: block;
  height: 24px;
  margin-top: 15px;
  border-bottom: 2px dashed #e5e5e5;
}
.slide-btn-wrapper .slide-btn {
  display: block;
  width: 225px;
  height: 44px;
  margin: 0 auto;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 2.5rem;
  color: #26303d;
  background-color: #fff;
  border: 2px solid #26303d;
  border-radius: 60px;
  transition: 0.3s 0s ease;
}
.slide-btn-wrapper .slide-btn:hover {
  background-color: #26303d;
  color: #fff;
}
@media only screen and (max-width: 576px) {
  .slide-btn-wrapper .slide-btn {
    background-color: #26303d;
    color: #fff;
  }
}
.slide-btn-wrapper .slide-btn:focus {
  outline: none;
}
.slide-btn-wrapper.hidden {
  display: none;
}
.std-pagination {
  display: flex;
  margin-top: 15px;
}
.std-pagination .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  font: normal 1.125rem BloggerSans-Medium, sans-serif;
  line-height: 2.875rem;
  color: #26303d;
  text-align: center;
  border: 2px solid #ffb142;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s 0s ease;
}
.std-pagination .page-item:hover {
  background: #ffb142;
}
.std-pagination .page-item.active {
  background: #ffb142;
}
.show-filters {
  margin-bottom: 30px;
  transition: 0.3s 0s ease;
}
.show-filters .show-wrapper {
  display: flex;
  padding: 15px;
}
@media only screen and (max-width: 992px) {
  .show-filters .show-wrapper {
    display: none;
  }
}
.show-filters .show-btn {
  display: block;
  width: 100%;
  height: 56px;
  background-color: #536377;
  border: none;
  border-radius: 5px;
}
.show-filters .show-btn:focus {
  outline: none;
}
.show-filters .show-btn .btn-text {
  width: 100%;
  height: 100%;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 3.5rem;
  color: #fff;
  background: url("/assets/img/icons/list.svg") no-repeat center right 35px;
}
.show-filters .show-btn .btn-text:last-child {
  display: none;
}
@media only screen and (min-width: 992px) {
  .show-filters .show-btn {
    display: none;
  }
}
.show-filters .show-group {
  margin-right: 40px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  border-bottom: 2px dashed #26303d;
  transition: 0.3s 0s ease;
  cursor: pointer;
}
.show-filters .show-group:hover {
  color: #23ad7b;
  border-bottom: 2px solid #23ad7b;
}
@media only screen and (max-width: 992px) {
  .show-filters .show-group {
    margin: 15px 0;
  }
}
.show-filters .show-group.active {
  color: #23ad7b;
  border-bottom: 2px solid #23ad7b;
}
.show-filters.active {
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}
.age-selector.active {
  background-color: #23ad7b;
}
.age-selector.active:after,
.age-selector.active:before {
  height: 0;
}
.age-selector.active .age-icon svg {
  fill: #fff;
}
.age-selector.active .age-text {
  color: #fff;
}
.age-selector {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 20px 13px;
  border: 2px solid #23ad7b;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s 0s ease;
}
.age-selector:before {
  content: '';
  position: absolute;
  top: -2px;
  right: 8px;
  width: 75px;
  height: 2px;
  background-color: #fff;
  transition: 0.2s 0s ease;
}
.age-selector:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 60px;
  width: 44px;
  height: 2px;
  background-color: #fff;
  transition: 0.2s 0s ease;
}
.age-selector .age-icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 13px;
}
.age-selector .age-icon svg {
  width: 100%;
  height: 100%;
  fill: #26303d;
}
.age-selector .age-text {
  flex-shrink: 0;
  font: normal 1.375rem BloggerSans-Medium, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
}
.age-selector .age-text span {
  display: block;
  margin-top: 5px;
  font: normal 1.375rem BloggerSans-Regular, sans-serif;
  line-height: 1.875rem;
}
.age-selector:hover {
  background-color: #23ad7b;
}
.age-selector:hover:after,
.age-selector:hover:before {
  height: 0;
}
.age-selector:hover .age-icon svg {
  fill: #fff;
}
.age-selector:hover .age-text {
  color: #fff;
}
.product-flags {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 50%;
}
.product-flags .flag-item {
  display: none;
  position: relative;
  margin-top: 8px;
  padding: 5px 20px;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #fff;
  background-color: #23ad7b;
}
.product-flags .flag-item:before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 21px solid #23ad7b;
  border-right: 14px solid transparent;
}
.product-flags .flag-item:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-bottom: 21px solid #23ad7b;
  border-right: 14px solid transparent;
}
.product-flags .flag-item.popular {
  background-color: #ffb142;
}
.product-flags .flag-item.popular:before {
  border-top: 21px solid #ffb142;
}
.product-flags .flag-item.popular:after {
  border-bottom: 21px solid #ffb142;
}
.product-flags .flag-item.gift {
  background-color: #ff714f;
}
.product-flags .flag-item.gift:before {
  border-top: 21px solid #ff714f;
}
.product-flags .flag-item.gift:after {
  border-bottom: 21px solid #ff714f;
}
.product-flags .flag-item.discount {
  background-color: #23ad7b;
}
.product-flags .flag-item.discount:before {
  border-top: 21px solid #23ad7b;
}
.product-flags .flag-item.discount:after {
  border-bottom: 21px solid #23ad7b;
}
.product-flags .flag-item.show {
  display: inline-block;
}
.product-flags.inner-flags {
  width: 30%;
  top: 33px;
}
.product-flags.inner-flags .flag-item {
  padding: 2px 10px;
}
.product-item {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  transition: 0.2s 0s ease;
}
.product-item.swiper-slide {
  min-height: 524px;
  height: inherit;
}
@media only screen and (max-width: 576px) {
  .product-item {
    margin-bottom: 30px;
  }
}
.product-item .product-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;
}
.product-item .product-img img {
  height: auto;
  width: auto;
  max-width: 100%;
}
.product-item .product-info {
  display: flex;
  flex-direction: column;
  height: calc(100% - 300px);
  padding: 10px 18px 35px 18px;
}
.product-item .product-info .product-name {
  font: normal 1.125rem BloggerSans-Regular, sans-serif;
  line-height: 1.875rem;
  color: #26303d;
}
.product-item .product-info .product-category .age-category {
  margin-right: 10px;
  font: normal 1.375rem BloggerSans-Medium, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
}
.product-item .product-info .product-category .weight-category {
  font: normal 1.375rem BloggerSans-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
}
.product-item .product-info .old-price {
  height: 30px;
  margin-top: auto;
  font: normal 1.3125rem BloggerSans-Regular, sans-serif;
  line-height: 1.875rem;
  color: #26303d;
  text-decoration: line-through;
  text-decoration-color: #ff714f;
  opacity: 0.8;
}
.product-item .product-info .product-price {
  margin: 10px 0;
  font: normal 2rem BloggerSans-Regular, sans-serif;
  line-height: 1.875rem;
  color: #23ad7b;
}
.product-item .product-info .product-buy {
  width: 100%;
  margin-top: auto;
  border: 2px solid #23ad7b;
  border-radius: 5px;
  font: normal 1.25rem BloggerSans-Medium, sans-serif;
  line-height: 2.75rem;
  color: #26303d;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s 0s ease;
}
@media only screen and (max-width: 576px) {
  .product-item .product-info .product-buy {
    background-color: #23ad7b;
    color: #fff;
  }
}
.product-item .product-info .product-buy:focus {
  outline: none;
}
.product-item:hover {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.product-item:hover .product-info .product-buy {
  background-color: #23ad7b;
  color: #fff;
}
.discount-item {
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  transition: 0.2s 0s ease;
}
.discount-item .discount-img {
  min-height: 354px;
  border-bottom: 1px solid #e5e5e5;
}
.discount-item .discount-info {
  padding: 16px 18px;
}
.discount-item .discount-info .discount-name {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.discount-item .discount-info .discount-date {
  margin-top: 10px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.0625rem;
  color: #26303d;
  opacity: 0.8;
}
.discount-item:hover {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.section-title {
  margin: 0;
  font: normal 3.25rem BloggerSans-Medium, sans-serif;
  line-height: 4.0625rem;
  color: #26303d;
}
@media only screen and (max-width: 576px) {
  .section-title {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }
}
.section-subtitle {
  font: normal 1.5rem Lato-Regular, sans-serif;
  line-height: 1.875rem;
  color: #26303d;
}
.main-cover {
  position: relative;
  height: 868px;
  background: url("/assets/img/back.jpg") no-repeat center;
  background-size: cover;
}
@media only screen and (max-width: 992px) {
  .main-cover {
    height: 1069px;
    background-position: left;
  }
}
@media only screen and (max-width: 768px) {
  .main-cover {
    height: 970px;
    padding-top: 70px;
  }
}
@media only screen and (max-width: 576px) {
  .main-cover {
    height: 860px;
  }
}
.main-cover:after {
  content: '';
  position: absolute;
  bottom: -120px;
  left: 0;
  width: 100%;
  height: 167px;
  background: url("/assets/img/wave-cream.svg") no-repeat center;
}
.main-cover .slide-container {
  overflow: hidden;
}
.main-cover .main-slide {
  height: auto;
  margin-top: 15%;
}
@media only screen and (max-width: 1200px) {
  .main-cover .main-slide {
    margin-top: 25%;
  }
}
@media only screen and (max-width: 992px) {
  .main-cover .main-slide {
    margin-top: 15%;
  }
}
.main-cover .main-slide .main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.main-cover .main-slide .main-info .info-label {
  position: relative;
  display: inline-block;
  width: 110px;
  padding: 10px 15px 10px 12px;
  font: normal 1.25rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #fff;
  background-color: #23ad7b;
}
@media only screen and (max-width: 576px) {
  .main-cover .main-slide .main-info .info-label {
    width: 58px;
    padding: 5px 8px;
    font-size: 0.875rem;
  }
}
.main-cover .main-slide .main-info .info-label:before {
  content: '';
  position: absolute;
  top: 0;
  right: -14px;
  width: 0;
  height: 0;
  border-top: 21px solid #23ad7b;
  border-right: 14px solid transparent;
}
.main-cover .main-slide .main-info .info-label:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -14px;
  width: 0;
  height: 0;
  border-bottom: 21px solid #23ad7b;
  border-right: 14px solid transparent;
}
.main-cover .main-slide .main-info .info-title {
  margin-top: 30px;
  font: normal 4.25rem BloggerSans-Medium, sans-serif;
  line-height: 5.9375rem;
  color: #26303d;
}
@media only screen and (max-width: 576px) {
  .main-cover .main-slide .main-info .info-title {
    font: normal 2.625rem BloggerSans-Medium, sans-serif;
  }
}
.main-cover .main-slide .main-info .info-text {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.main-cover .main-slide .main-info .info-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 356px;
  height: 76px;
  margin-top: 33px;
  border: 1px solid #ffb142;
  border-radius: 70px;
  transition: 0.4s 0s ease;
}
.main-cover .main-slide .main-info .info-link span {
  display: flex;
  align-items: center;
  padding: 16px 60px;
  font: normal 1.375rem BloggerSans-Medium, sans-serif;
  line-height: 1.375rem;
  color: #26303d;
  border-radius: 70px;
  background: #ffa836;
  background: -moz-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -webkit-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -o-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -ms-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: linear-gradient(0deg, #ffa836 30%, #ffcc63 70%);
  transition: 0.8s 0s ease;
}
.main-cover .main-slide .main-info .info-link span:hover {
  background: #ffcc63;
  background: -moz-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -webkit-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -o-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -ms-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: linear-gradient(0deg, #ffa836 0%, #ffcc63 100%);
}
.main-cover .main-slide .main-info .info-link span img {
  flex-shrink: 0;
  margin-left: 15px;
  width: 20px;
  height: 14px;
}
.main-cover .main-slide .main-info .info-link:hover span img {
  animation: wiggle 1.5s linear infinite;
}
@media only screen and (max-width: 768px) {
  .main-cover .main-slide .main-info .info-link {
    width: 290px;
  }
  .main-cover .main-slide .main-info .info-link span {
    padding: 18px 28px;
  }
}
.main-cover .main-slide .main-info .info-pagination {
  margin-top: 35px;
}
.main-cover .main-slide .main-info .info-pagination .swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: transparent;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  opacity: 1;
}
.main-cover .main-slide .main-info .info-pagination .swiper-pagination-bullet-active {
  position: relative;
  border: 6px solid #23ad7b;
  animation: rotate 5s linear infinite;
}
.main-cover .main-slide .main-info .info-pagination .swiper-pagination-bullet-active:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 0;
  width: 4px;
  height: 15px;
  transform: rotate(-36deg);
  background: #fff4e8;
}
.main-cover .slider-product {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.main-cover .slider-product .product-img {
  height: 330px;
}
.main-cover .slider-product .product-img img {
  width: auto;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .main-cover .slider-product .product-img {
    height: 240px;
  }
}
@media only screen and (max-width: 576px) {
  .main-cover .slider-product .product-img {
    height: auto;
  }
  .main-cover .slider-product .product-img img {
    width: 100%;
    height: auto;
  }
}
.main-cover .slider-product .product-title {
  font: 400 1.125rem BloggerSans-Medium, sans-serif;
  text-align: center;
  margin-top: auto;
}
.main-cover .slider-product .product-oldprice {
  font: 400 1.375rem BloggerSans-Medium, sans-serif;
  text-align: center;
  text-decoration: line-through;
  text-decoration-color: #ff714f;
}
.main-cover .slider-product .product-discountprice {
  font: 400 1.625rem BloggerSans-Medium, sans-serif;
  text-align: center;
  color: #ff714f;
}
.advantages {
  margin-top: 120px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .advantages {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 576px) {
  .advantages {
    margin-top: 30px;
  }
}
.advantages .advatage-title {
  position: relative;
  display: inline-block;
  font: normal 1.625rem BadScript-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.advantages .advatage-title:before {
  content: '';
  position: absolute;
  top: -20px;
  right: -120px;
  width: 108px;
  height: 85px;
  background: url("/assets/img/icons/arrow-draw_.svg") no-repeat center;
  background-size: contain;
}
@media only screen and (max-width: 576px) {
  .advantages .advatage-title:before {
    right: -70px;
    width: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .advantages .advatage-title {
    margin-bottom: 15px;
  }
}
.advantages .advantage-block {
  margin-top: 15px;
  padding: 20px 25px;
  border-radius: 15px;
  border-top-right-radius: 60px;
}
.advantages .advantage-block .advantage-icon {
  height: 50px;
}
.advantages .advantage-block .advantage-icon img {
  width: auto;
  height: 100%;
}
.advantages .advantage-block .advantage-name {
  margin-top: 10px;
  font: normal 1.5rem BloggerSans-Medium, sans-serif;
  line-height: 1.875rem;
  color: #fff;
}
.advantages .advantage-block .advantage-test {
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.375rem;
  color: #fff;
}
.advantages .advantage-block.color-theme-1 {
  background: #23ad7b;
  background: -moz-linear-gradient(270deg, #23ad7b 30%, #54d9a9 70%);
  background: -webkit-linear-gradient(270deg, #23ad7b 30%, #54d9a9 70%);
  background: -o-linear-gradient(270deg, #23ad7b 30%, #54d9a9 70%);
  background: -ms-linear-gradient(270deg, #23ad7b 30%, #54d9a9 70%);
  background: linear-gradient(0deg, #23ad7b 30%, #54d9a9 70%);
  transition: 0.8s 0s ease;
}
.advantages .advantage-block.color-theme-1:hover {
  background: #54d9a9;
  background: -moz-linear-gradient(270deg, #23ad7b 0%, #54d9a9 100%);
  background: -webkit-linear-gradient(270deg, #23ad7b 0%, #54d9a9 100%);
  background: -o-linear-gradient(270deg, #23ad7b 0%, #54d9a9 100%);
  background: -ms-linear-gradient(270deg, #23ad7b 0%, #54d9a9 100%);
  background: linear-gradient(0deg, #23ad7b 0%, #54d9a9 100%);
}
.advantages .advantage-block.color-theme-2 {
  background: #ffa836;
  background: -moz-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -webkit-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -o-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: -ms-linear-gradient(270deg, #ffa836 30%, #ffcc63 70%);
  background: linear-gradient(0deg, #ffa836 30%, #ffcc63 70%);
  transition: 0.8s 0s ease;
}
.advantages .advantage-block.color-theme-2:hover {
  background: #ffcc63;
  background: -moz-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -webkit-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -o-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: -ms-linear-gradient(270deg, #ffa836 0%, #ffcc63 100%);
  background: linear-gradient(0deg, #ffa836 0%, #ffcc63 100%);
}
.advantages .advantage-block.color-theme-3 {
  background: #ff714f;
  background: -moz-linear-gradient(270deg, #ff714f 30%, #ff8e72 70%);
  background: -webkit-linear-gradient(270deg, #ff714f 30%, #ff8e72 70%);
  background: -o-linear-gradient(270deg, #ff714f 30%, #ff8e72 70%);
  background: -ms-linear-gradient(270deg, #ff714f 30%, #ff8e72 70%);
  background: linear-gradient(0deg, #ff714f 30%, #ff8e72 70%);
  transition: 0.8s 0s ease;
}
.advantages .advantage-block.color-theme-3:hover {
  background: #ff8e72;
  background: -moz-linear-gradient(270deg, #ff714f 0%, #ff8e72 100%);
  background: -webkit-linear-gradient(270deg, #ff714f 0%, #ff8e72 100%);
  background: -o-linear-gradient(270deg, #ff714f 0%, #ff8e72 100%);
  background: -ms-linear-gradient(270deg, #ff714f 0%, #ff8e72 100%);
  background: linear-gradient(0deg, #ff714f 0%, #ff8e72 100%);
}
.index-catalog {
  margin-top: 60px;
  margin-bottom: 15px;
  overflow-x: hidden;
}
.index-catalog .catalog-slider {
  margin-top: 30px;
}
@media only screen and (max-width: 576px) {
  .index-catalog .catalog-slider .swiper-wrapper {
    flex-wrap: wrap;
  }
}
.slider-controls {
  display: flex;
  margin-top: 30px;
}
@media only screen and (max-width: 576px) {
  .slider-controls {
    flex-wrap: wrap;
    margin: 0;
  }
}
.slider-controls .buttons {
  display: flex;
}
@media only screen and (max-width: 576px) {
  .slider-controls .buttons {
    margin: 15px 0 30px;
  }
}
.slider-controls .buttons .control-btn {
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  background-color: #ffb142;
  background-repeat: no-repeat;
  background-size: 20px 14px;
  background-position: center;
  border-radius: 50%;
  transition: 0.3s 0s ease;
  cursor: pointer;
}
.slider-controls .buttons .control-btn:hover {
  background-color: #23ad7b;
}
.slider-controls .buttons .green {
  background-color: #23ad7b;
}
.slider-controls .buttons .green:hover {
  background-color: #ffb142;
}
.slider-controls .buttons .cert-prev,
.slider-controls .buttons .catalog-prev,
.slider-controls .buttons .discount-prev {
  background-image: url("/assets/img/icons/left-arrow-w.svg");
}
.slider-controls .buttons .cert-next,
.slider-controls .buttons .catalog-next,
.slider-controls .buttons .discount-next {
  background-image: url("/assets/img/icons/right-arrow-w.svg");
}
.slider-controls .link-btn {
  width: 253px;
  height: 52px;
  margin-left: 25px;
  background-color: #ffb142;
  border-radius: 5px;
  transition: 0.3s 0s ease;
}
@media only screen and (max-width: 576px) {
  .slider-controls .link-btn {
    width: 100%;
    margin: 0;
  }
}
.slider-controls .link-btn span {
  display: block;
  padding-right: 30px;
  font: normal 1.25rem BloggerSans-Medium, sans-serif;
  line-height: 3.25rem;
  color: #26303d;
  text-align: center;
  background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 30px;
  transition: 0.3s 0s ease;
}
.slider-controls .link-btn:hover {
  background-color: #23ad7b;
}
.slider-controls .link-btn:hover span {
  color: #fff;
  background: url("/assets/img/icons/right-arrow-w.svg") no-repeat center right 30px;
}
.slider-controls .green {
  background-color: #23ad7b;
}
.slider-controls .green:hover {
  background-color: #ffb142;
}
.slider-controls .whiteText span {
  color: #fff;
  background: url("/assets/img/icons/right-arrow-w.svg") no-repeat center right 30px;
}
.slider-controls.show-controls {
  flex-wrap: wrap;
}
.slider-controls.show-controls .buttons {
  display: flex;
  margin-bottom: 18px;
}
.index-callback {
  position: relative;
  display: flex;
  align-items: center;
  background: url("/assets/img/callback-bg.svg") no-repeat center;
  background-size: cover;
}
@media only screen and (max-width: 1200px) {
  .index-callback {
    padding: 130px 0;
    background-position: inherit;
  }
}
@media only screen and (max-width: 992px) {
  .index-callback {
    padding: 90px 0 0;
  }
}
.index-callback .callback-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.index-callback .callback-wrapper .callback-img {
  width: 520px;
  margin-right: -200px;
}
@media only screen and (max-width: 1510px) {
  .index-callback .callback-wrapper .callback-img {
    width: 330px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .index-callback .callback-wrapper .callback-img {
    width: 520px;
  }
}
.index-callback .callback-wrapper .callback-info {
  width: 70%;
}
@media only screen and (max-width: 1200px) {
  .index-callback .callback-wrapper .callback-info {
    width: 100%;
  }
}
.index-callback .callback-wrapper .callback-info .callback-form {
  display: flex;
  margin-top: 30px;
  z-index: 1;
}
@media only screen and (max-width: 1200px) {
  .index-callback .callback-wrapper .callback-info .callback-form {
    flex-wrap: wrap;
  }
}
.index-callback .callback-wrapper .callback-info .callback-form .callback-input {
  width: 253px;
  height: 52px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0 19px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 3.25rem;
  color: #26303d;
  background-color: #fff4e8;
  border: 2px solid #ffb142;
  border-radius: 5px;
}
.index-callback .callback-wrapper .callback-info .callback-form .callback-input::-webkit-input-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-input::-moz-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-input:-ms-input-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-input:-moz-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-input::placeholder {
  font: normal 1.125rem Lato-Regular, sans-serif;
  color: #26303d;
}
@media only screen and (max-width: 768px) {
  .index-callback .callback-wrapper .callback-info .callback-form .callback-input {
    width: 100%;
    margin-right: 0;
  }
}
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit {
  background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 60px #ffb142;
  margin: 0;
  padding: 0;
  padding-right: 30px;
}
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit::-webkit-input-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit::-moz-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit:-ms-input-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit:-moz-placeholder,
.index-callback .callback-wrapper .callback-info .callback-form .callback-submit::placeholder {
  font: normal 1.25rem BloggerSans-Medium, sans-serif;
  color: #26303d;
}
.privacy-text {
  max-width: 435px;
  margin-top: 11px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  opacity: 0.8;
}
.privacy-text a {
  display: inline;
  text-decoration: underline;
}
.discounts {
  margin-top: 30px;
  margin-bottom: 30px;
  background: url("/assets/img/discounts-bg.svg") no-repeat center;
}
.discounts .discounts-container {
  overflow: hidden;
}
.index-seo {
  padding: 60px 0;
  background: url("/assets/img/seo-bg.svg") no-repeat center;
}
@media only screen and (max-width: 576px) {
  .index-seo {
    padding: 30px 0;
  }
}
.index-seo .seo-text {
  height: auto;
}
.index-seo .seo-text h1 {
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
}
.index-seo .seo-text h1,
.index-seo .seo-text h2,
.index-seo .seo-text h3,
.index-seo .seo-text h4,
.index-seo .seo-text h5 {
  font-family: BloggerSans-Medium, sans-serif;
  color: #26303d;
  line-height: 1.5;
  margin: 0;
}
.index-seo .seo-text p {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  margin: 15px 0;
}
.index-seo .seo-text.closed {
  position: relative;
  height: 315px;
  overflow: hidden;
}
.index-seo .seo-slider {
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .index-seo .seo-slider {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 576px) {
  .index-seo .seo-slider {
    margin-top: 30px;
  }
}
.index-seo .seo-slider .seo-pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.index-seo .seo-slider .seo-pagination .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  background: #fff;
  opacity: 1;
}
.index-seo .seo-slider .seo-pagination .swiper-pagination-bullet-active {
  background: #23ad7b;
}
.footer {
  position: relative;
  margin-top: 75px;
  padding: 40px 0 75px;
  background: #fff4e8;
}
.footer:before {
  content: '';
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: 75px;
  background: url("/assets/img/cream-wave-crop.svg") no-repeat center;
  background-size: cover;
}
.footer .footer-container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .footer .footer-container {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 768px) {
  .footer .footer-container .footer-contacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.footer .footer-container .footer-contacts .f-contact-line {
  margin-bottom: 30px;
  padding-left: 30px;
}
.footer .footer-container .footer-contacts .f-contact-line .f-contact-text {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.footer .footer-container .footer-contacts .f-contact-line .f-contact-name {
  display: inline-block;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  border-bottom: 1px dashed #26303d;
}
.footer .footer-container .footer-contacts .f-contact-line .map-icon {
  position: relative;
}
.footer .footer-container .footer-contacts .f-contact-line .map-icon:before {
  content: '';
  position: absolute;
  top: 5px;
  left: -25px;
  z-index: 1;
  width: 17px;
  height: 17px;
  background: url("/assets/img/icons/maps-black.svg") no-repeat center;
  background-size: contain;
}
.footer .footer-container .footer-contacts .f-contact-line .phone-icon {
  position: relative;
}
.footer .footer-container .footer-contacts .f-contact-line .phone-icon:before {
  content: '';
  position: absolute;
  top: 5px;
  left: -25px;
  z-index: 1;
  width: 17px;
  height: 17px;
  background: url("/assets/img/icons/call-black.svg") no-repeat center;
  background-size: contain;
}
.footer .footer-container .footer-menu .footer-title {
  font: bolder 1.125rem Lato-Bold, sans-serif;
  line-height: 2rem;
  color: #26303d;
}
.footer .footer-container .footer-menu .footer-item {
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 2rem;
  color: #26303d;
}
.footer .footer-container .footer-menu .before {
  position: relative;
}
.footer .footer-container .footer-menu .before:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background: #26303d;
  transition: 0.3s 0s ease;
}
.footer .footer-container .footer-menu .before:hover:before {
  width: 100%;
}
.footer .footer-container .footer-socials {
  display: flex;
  margin-top: 24px;
}
.footer .footer-container .footer-socials .f-social-link {
  width: 40px;
  height: 29px;
  margin-right: 15px;
  transition: 0.3s 0s ease;
}
.footer .footer-container .footer-socials .f-social-link svg {
  width: 100%;
  height: 100%;
  fill: Lato-Regular;
}
.footer .footer-container .footer-socials .f-social-link:hover {
  transform: scale(1.1);
}
.footer .footer-container .footer-socials .f-social-link:hover svg {
  fill: #23ad7b;
}
@media only screen and (min-width: 576px) {
  .footer .footer-container .two-column-sm {
    column-count: 2;
    column-gap: 200px;
  }
}
.footer-copyrights {
  position: relative;
  background-color: #23ad7b;
  padding-top: 25px;
  padding-bottom: 25px;
}
.footer-copyrights:before {
  content: '';
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: 75px;
  background: url("/assets/img/green-wave-crop.svg") no-repeat center;
  background-size: cover;
}
.footer-copyrights .copyrights-container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .footer-copyrights .copyrights-container {
    flex-wrap: wrap;
  }
}
.footer-copyrights .copyrights-container .copyright-text {
  display: flex;
  align-items: center;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.125rem;
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .footer-copyrights .copyrights-container .copyright-text {
    margin: 5px 0;
  }
}
.footer-copyrights .copyrights-container .copyright-text a img {
  margin-left: 5px;
  margin-top: 4px;
}
.footer-copyrights .copyrights-container .footer-privacy {
  text-decoration: underline;
}
.modals-hangar {
  display: none;
}
.modal-window {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff4e8;
}
.modal-window .modal-title {
  font: normal 3.25rem BloggerSans-Medium, sans-serif;
  line-height: 3.5rem;
  color: #26303d;
  text-align: center;
}
.modal-window .modal-form .modal-input {
  display: block;
  width: 364px;
  height: 52px;
  margin: 22px auto 0;
  padding-left: 20px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 3rem;
  color: #26303d;
  background: #fff4e8;
  border: 2px solid #ffb142;
  border-radius: 5px;
}
.modal-window .modal-form .modal-input::-webkit-input-placeholder,
.modal-window .modal-form .modal-input::-moz-placeholder,
.modal-window .modal-form .modal-input:-ms-input-placeholder,
.modal-window .modal-form .modal-input:-moz-placeholder,
.modal-window .modal-form .modal-input::placeholder {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 3rem;
  color: #26303d;
}
.modal-window .modal-form .modal-message {
  height: 120px;
  padding: 20px;
  border-color: #e5e5e5;
  line-height: 2rem;
  resize: none;
}
.modal-window .modal-form .modal-message::-webkit-input-placeholder,
.modal-window .modal-form .modal-message::-moz-placeholder,
.modal-window .modal-form .modal-message:-ms-input-placeholder,
.modal-window .modal-form .modal-message:-moz-placeholder,
.modal-window .modal-form .modal-message::placeholder {
  padding: 0;
  line-height: 2rem;
}
.modal-window .modal-form .modal-submit {
  padding-right: 30px;
  background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 100px #ffb142;
}
.modal-window .modal-privacy {
  margin-top: 15px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  text-align: center;
  opacity: 0.7;
}
.modal-window .modal-privacy a {
  display: inline;
  text-decoration: underline;
}
.modal-window .modal-controls {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}
.modal-window .modal-controls svg {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  fill: #23ad7b;
  transition: 0.3s 0s ease;
}
.modal-window .modal-controls .control-text {
  margin-top: 3px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  opacity: 0.7;
}
.modal-window .modal-controls:hover svg {
  fill: #ffb142;
  transform: scale(1.1);
}
@media only screen and (max-width: 576px) {
  .modal-window {
    padding: 90px 15px 30px;
  }
  .modal-window .modal-title {
    font-size: 2.875rem;
    line-height: 3.25rem;
  }
  .modal-window .modal-controls {
    top: 30px;
    right: 15px;
  }
  .modal-window .modal-controls svg {
    width: 25px;
    height: 25px;
  }
  .modal-window .modal-form .modal-input {
    width: 100%;
  }
  .modal-window .modal-form .modal-submit {
    padding-right: 50px;
    background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 70px #ffb142;
  }
}
.inner-page {
  margin-bottom: 145px;
  padding-top: 220px;
}
@media only screen and (max-width: 992px) {
  .inner-page {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 768px) {
  .inner-page {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .inner-page {
    padding-top: 90px;
  }
}
.inner-page .section-title {
  margin-bottom: 30px;
}
.filter-toggle {
  width: 100%;
  height: 56px;
  background-color: #ffb142;
  border-radius: 5px;
  border: none;
}
.filter-toggle:focus {
  outline: none;
}
.filter-toggle .btn-text {
  display: block;
  width: 100%;
  height: 100%;
  font: normal 1.25rem BloggerSans-Medium, sans-serif;
  line-height: 3.5rem;
  color: #26303d;
  background: url("/assets/img/icons/settings.svg") no-repeat center right 35px;
}
.filter-toggle .btn-text:last-child {
  display: none;
}
@media only screen and (min-width: 992px) {
  .filter-toggle {
    display: none;
  }
}
.filter-placeholder {
  display: none;
  opacity: 0;
}
.filter-bar {
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  transition: 0.3s 0s ease;
}
@media only screen and (min-width: 992px) {
  .filter-bar {
    margin-top: -115px;
  }
}
@media only screen and (max-width: 992px) {
  .filter-bar {
    margin: 15px 0;
    box-shadow: none;
  }
}
.filter-bar .filter-form {
  padding: 15px;
}
@media only screen and (max-width: 992px) {
  .filter-bar .filter-form {
    display: none;
  }
}
.filter-bar .filter-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  margin-top: 20px;
  font: normal 1.125rem/50px Lato-Regular, sans-serif;
  line-height: 1.625rem;
  text-align: center;
  color: #fff;
  border: 2px solid #23ad7b;
  border-radius: 5px;
  background-color: #23ad7b;
}
@media only screen and (max-width: 992px) {
  .filter-bar .filter-title {
    display: none;
  }
}
.filter-bar .catalog-filter .filter-group {
  padding: 0;
  margin-top: 15px;
  margin-bottom: 28px;
}
.filter-bar .catalog-filter .filter-group .group-name {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.filter-bar .catalog-filter .filter-group .filter-line {
  display: flex;
  margin-top: 10px;
}
.filter-bar .catalog-filter .filter-group .filter-line .input-label {
  margin-right: 8px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 2.0625rem;
  color: #26303d;
}
.filter-bar .catalog-filter .filter-group .filter-line .filter-price {
  width: 78px;
  height: 33px;
  padding-left: 8px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 2.0625rem;
  color: #26303d;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.filter-bar .catalog-filter .filter-group .filter-line .filter-price:nth-child(2) {
  margin-right: 8px;
}
@media only screen and (max-width: 1200px) {
  .filter-bar .catalog-filter .filter-group .filter-line {
    flex-wrap: wrap;
  }
  .filter-bar .catalog-filter .filter-group .filter-line .filter-price {
    width: auto;
  }
}
@media only screen and (max-width: 992px) {
  .filter-bar .catalog-filter .filter-group .filter-line .filter-price {
    width: 78px;
  }
}
.filter-bar .catalog-filter .filter-group .filter-line .checkbox-input {
  width: auto;
}
.filter-bar .catalog-filter .filter-group .filter-line .checkbox-label {
  position: relative;
  padding-left: 30px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.5rem;
  color: #26303d;
  cursor: pointer;
}
.filter-bar .catalog-filter .filter-group .filter-line .checkbox-label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: url("/assets/img/icons/checkbox.svg") no-repeat center;
  background-size: contain;
}
.filter-bar .catalog-filter .filter-group .filter-line .checkbox-input {
  display: none;
}
.filter-bar .catalog-filter .filter-group .filter-line .checkbox-input:checked ~ .checkbox-label:before {
  background: url("/assets/img/icons/checkbox-checked.svg") no-repeat center;
  background-size: contain;
}
.filter-bar .catalog-filter .filter-group .filter-line .vendor-select {
  width: 100%;
  height: 44px;
  padding-left: 10px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 2.5rem;
  color: #26303d;
  background: url("/assets/img/icons/dropdown-black.svg") no-repeat center right 13px;
  border: 2px solid #ffb142;
  border-radius: 5px;
  appearance: none;
}
.filter-bar .catalog-filter .filter-group .filter-line .filter-results {
  width: 100%;
  height: 44px;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  color: #fff;
  text-align: center;
  background: #536377;
  border-radius: 5px;
  border: 2px solid #536377;
  cursor: pointer;
}
.filter-bar .catalog-filter .filter-group .filter-line .filter-close {
  display: none;
  width: 100%;
  height: 44px;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 2.5rem;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  background: #23ad7b;
  border-radius: 5px;
  border: 2px solid #23ad7b;
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .filter-bar .catalog-filter .filter-group .filter-line .filter-close {
    display: block;
  }
}
.filter-bar.active {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}
.breadcrumbs {
  opacity: 0.8;
}
.breadcrumbs .crumb-item {
  display: inline;
  padding-left: 10px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
}
.breadcrumbs .crumb-item:first-child {
  padding-left: 0;
}
.page-seo {
  margin-top: 60px;
}
.page-seo .seo-point {
  margin-bottom: 15px;
}
.page-seo .seo-point .point-icon {
  width: 67px;
  height: 67px;
  margin-bottom: 11px;
}
.page-seo .seo-point .point-icon img {
  width: auto;
  height: 100%;
}
.page-seo .seo-point .point-title {
  font: bold 1.25rem Lato-Bold, sans-serif;
  line-height: 1.5rem;
  color: #26303d;
}
.page-seo .seo-point .point-text {
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.page-seo .page-text {
  margin-top: 25px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  opacity: 0.8;
}
.product-slider {
  position: relative;
  width: 100%;
  padding: 30px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
}
.product-slider .image-slide {
  cursor: zoom-in;
}
.product-slider .image-slide:before {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: url("/assets/img/icons/search.svg") no-repeat center #23ad7b;
  background-size: 23px;
  opacity: 0;
  transition: 0.3s 0s ease;
}
.product-slider .image-slide:hover:before {
  opacity: 1;
}
.product-thumbs {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.product-thumbs .thumb-slide {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.share-socials {
  display: flex;
  align-items: center;
  margin: 40px 0;
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.375rem;
  color: #26303d;
}
.share-socials .share-wrapper {
  display: flex;
  width: 100%;
  margin-left: 13px;
}
@media only screen and (max-width: 768px) {
  .share-socials .share-wrapper {
    margin-top: 10px;
  }
}
.share-socials .share-wrapper .share-btn {
  width: 27px;
  height: 27px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 5px;
}
@media only screen and (max-width: 768px) {
  .share-socials {
    margin: 20px 0;
  }
}
.product-form {
  padding: 20px;
  padding-bottom: 46px;
  background: url("/assets/img/form-bg.svg") no-repeat center #fff4e8;
  background-size: cover;
}
.product-form .form-title {
  font: normal 2.625rem BloggerSans-Medium, sans-serif;
  line-height: 3.5rem;
  color: #26303d;
}
.product-form .form-text {
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-form .form-inputs .input-style {
  width: 253px;
  height: 52px;
  margin-top: 17px;
  padding-left: 23px;
  background-color: transparent;
  border: 2px solid #ffb142;
  border-radius: 5px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-form .form-inputs .input-style::-webkit-input-placeholder,
.product-form .form-inputs .input-style::-moz-placeholder,
.product-form .form-inputs .input-style:-ms-input-placeholder,
.product-form .form-inputs .input-style:-moz-placeholder,
.product-form .form-inputs .input-style::placeholder {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-form .form-inputs .send-btn {
  padding: 0;
  padding-right: 60px;
  background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 30px #ffb142;
}
.product-form .form-inputs .privacy-text {
  margin-top: 15px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  opacity: 0.8;
}
.product-form .form-inputs .privacy-text a {
  display: inline;
  text-decoration: underline;
}
.hotline-block {
  margin: 35px 0;
  padding-left: 45px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  background: url("/assets/img/icons/question-mark.svg") no-repeat top left;
}
.hotline-block a {
  display: block;
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-page-info {
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}
.product-page-info .info-section {
  padding: 0 25px 35px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
}
@media only screen and (max-width: 576px) {
  .product-page-info .info-section {
    padding: 0 15px 15px;
  }
}
.product-page-info .product-characteristics {
  overflow: hidden;
  height: auto;
}
.product-page-info .product-characteristics.closed {
  height: 250px;
}
.product-page-info .show-chr-btn {
  margin-top: 30px;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.1875rem;
  color: #26303d;
  background: none;
  border: none;
  border-bottom: 2px dashed #e5e5e5;
}
.product-page-info .show-chr-btn:focus {
  outline: none;
}
.product-page-info .buy-btn {
  display: block;
  width: 157px;
  height: 44px;
  margin-left: 30px;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 2.75rem;
  color: #fff;
  text-align: center;
  background-color: #23ad7b;
  border: none;
  border-radius: 5px;
}
.product-page-info .buy-btn:focus {
  outline: none;
}
@media only screen and (max-width: 576px) {
  .product-page-info .buy-btn {
    width: 100%;
    margin: 0;
    margin-top: 15px;
  }
}
.product-page-info .info-line {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .product-page-info .info-line {
    margin-top: 15px;
    justify-content: space-between;
  }
}
.product-page-info .info-line .info-title {
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.1875rem;
  color: #26303d;
}
.product-page-info .info-line .info-old-price {
  margin-left: 10px;
  font: normal 1.3125rem BloggerSans-Regular, sans-serif;
  line-height: 1.875rem;
  color: #26303d;
  text-decoration: line-through;
  text-decoration-color: #ff714f;
}
@media only screen and (max-width: 576px) {
  .product-page-info .info-line .info-old-price {
    flex-shrink: 0;
    margin: 0;
  }
}
.product-page-info .info-line .info-price {
  margin-left: 10px;
  font: normal 2rem BloggerSans-Regular, sans-serif;
  line-height: 2rem;
  color: #23ad7b;
}
@media only screen and (max-width: 576px) {
  .product-page-info .info-line .info-price {
    flex-shrink: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 576px) {
  .product-page-info .info-line {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.product-page-info .info-line .dots {
  height: 11px;
  flex-grow: 1;
  margin-left: 4px;
  background: url("/assets/img/icons/dot.svg") repeat-x bottom;
  background-size: 7px;
}
@media only screen and (max-width: 576px) {
  .product-page-info .info-line .dots {
    display: none;
  }
}
.product-page-info .info-line.no-wrap {
  flex-wrap: nowrap;
}
.product-page-info .info-line.zero-margin {
  margin: 0;
}
.product-page-info .color-picks {
  margin-top: 45px;
}
@media only screen and (max-width: 576px) {
  .product-page-info .color-picks {
    margin-top: 0;
  }
}
.product-page-info .color-picks .color-title {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.375rem;
  color: #26303d;
}
.product-page-info .color-picks .colors-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
@media only screen and (max-width: 576px) {
  .product-page-info .color-picks .colors-list {
    width: auto;
  }
}
.product-page-info .color-picks .colors-list .color-block {
  width: 96px;
  margin-right: 15px;
}
@media only screen and (max-width: 576px) {
  .product-page-info .color-picks .colors-list .color-block {
    width: auto;
  }
}
.product-page-info .color-picks .colors-list .color-block .color-circle {
  position: relative;
  display: block;
  width: 52px;
  height: 52px;
  margin: 0 auto;
  margin-bottom: 8px;
  border-radius: 50%;
  cursor: pointer;
}
.product-page-info .color-picks .colors-list .color-block .color-circle:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("/assets/img/icons/checked.svg") no-repeat center;
  background-size: 26px 20px;
  opacity: 0;
  transition: 0.2s 0s ease;
}
@media only screen and (max-width: 576px) {
  .product-page-info .color-picks .colors-list .color-block .color-circle {
    width: 40px;
    height: 40px;
  }
}
.product-page-info .color-picks .colors-list .color-block .color-radio {
  display: none;
}
.product-page-info .color-picks .colors-list .color-block .color-radio:checked ~ .color-circle:before {
  opacity: 1;
}
.product-page-info .color-picks .colors-list .color-block .color-name {
  margin-top: 10px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.0625rem;
  color: #26303d;
  text-align: center;
  opacity: 0.7;
}
@media only screen and (max-width: 576px) {
  .product-page-info .color-picks .colors-list .color-block .color-name {
    display: none;
  }
}
.product-page-info .page-seo {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  margin: 0;
}
.product-page-info .page-seo .seo-point {
  flex-shrink: 0.2;
  margin-right: 50px;
}
@media only screen and (max-width: 1200px) {
  .product-page-info .page-seo {
    flex-wrap: wrap;
  }
  .product-page-info .page-seo .seo-point {
    width: 45%;
    margin: 0;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .product-page-info .page-seo .seo-point {
    width: 100%;
  }
}
.product-page-info .product-description.closed {
  overflow: hidden;
  height: 355px;
}
.product-page-info .product-description {
  padding-top: 15px;
}
.product-page-info .product-description h2 {
  margin: 0;
  font: bold 1rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-page-info .product-description p {
  margin: 0;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.product-page-info .product-description table {
  border-spacing: 7px 5px;
  border-collapse: separate;
}
.product-video {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font: normal 1rem Lato-Regular, sans-serif;
  line-height: 1.1875rem;
  color: #26303d;
}
.product-video span {
  border-bottom: 1px dashed transparent;
  transition: 0.3s 0s ease;
}
.product-video svg {
  margin-right: 15px;
  fill: #f00;
  size: 30px;
  opacity: 0.7;
  transition: 0.3s 0s ease;
}
.product-video:hover span {
  border-bottom: 1px dashed #26303d;
}
.product-video:hover svg {
  fill: #f00;
  opacity: 1;
}
.rating {
  display: flex;
  margin-left: 15px;
}
.rating .star-item {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 5px;
  fill: #bbb;
}
.rating .filled {
  fill: #23ad7b;
}
.rating .rate-number {
  margin: 0 15px;
  font: bold 1.625rem Lato-Bold, sans-serif;
  line-height: 1rem;
  color: #26303d;
}
.other-products {
  overflow: hidden;
}
.other-products .others-title {
  margin-bottom: 15px;
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
  line-height: 2.25rem;
  color: #26303d;
}
.information {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.information h2 {
  margin-top: 30px;
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
  line-height: 2.25rem;
  color: #26303d;
}
.information img {
  display: inline;
  width: auto;
  margin-left: 10px;
}
.serificates-section {
  overflow: hidden;
}
@media only screen and (max-width: 992px) {
  .serificates-section {
    padding-bottom: 30px;
  }
}
.serificates-section .serificates-slider {
  overflow: hidden;
}
.serificates-section .serificates-slider .cert-item {
  position: relative;
  transition: 0.3s 0s ease;
}
.serificates-section .serificates-slider .cert-item:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: url("/assets/img/icons/search.svg") no-repeat center #23ad7b;
  background-size: 22px;
  transition: 0.3s 0s ease;
  opacity: 0;
}
.serificates-section .serificates-slider .cert-item:hover {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}
.serificates-section .serificates-slider .cert-item:hover:before {
  opacity: 1;
}
.brands-section {
  margin-top: 30px;
}
.brands-section .brend-item {
  margin-bottom: 25px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.brands-section .brend-item .brand-text {
  padding: 0 11px;
  padding-bottom: 11px;
  font: bold 1rem Lato-Bold, sans-serif;
  line-height: 1.375rem;
  color: defColors;
}
.delivery-text {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.delivery-text h2 {
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
  line-height: 2.25rem;
  color: #26303d;
}
.delivery-text img {
  display: inline;
  width: auto;
}
.delivery-text b {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.reviews .review-block {
  padding: 30px 25px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
  margin-bottom: 20px;
}
.reviews .review-block .review-author {
  margin-right: 15px;
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.reviews .review-block .review-date {
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  opacity: 0.7;
}
.reviews .review-block .review-text {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.reviews .review-block .review-gallery {
  display: flex;
  margin-top: 15px;
}
@media only screen and (max-width: 768px) {
  .reviews .review-block .review-gallery {
    flex-wrap: wrap;
  }
}
.reviews .review-block .review-gallery .big-image {
  width: 48.3%;
  flex-shrink: 0;
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .reviews .review-block .review-gallery .big-image {
    display: none;
  }
}
.reviews .review-block .review-gallery .small-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reviews .review-block .review-gallery .small-images .review-image {
  width: 48%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 576px) {
  .reviews .review-block .review-gallery .small-images .review-image {
    width: 46%;
  }
  .reviews .review-block .review-gallery .small-images .review-image:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .reviews .review-block .review-gallery .small-images .review-image:first-child {
    display: none;
  }
}
.reviews .review-block .review-video {
  margin-top: 15px;
}
.reviews .review-block .review-video iframe {
  width: 100%;
}
.reviews .review-block .review-title {
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
  line-height: 2.25rem;
  color: #26303d;
}
.reviews .review-block .leave-review .review-input {
  display: block;
  width: 100%;
  height: 53px;
  padding: 17px;
  margin-bottom: 11px;
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
  background: transparent;
  border: 2px solid #e5e5e5;
  border-radius: 5px;
  opacity: 0.7;
}
.reviews .review-block .leave-review .review-input::-webkit-input-placeholder,
.reviews .review-block .leave-review .review-input::-moz-placeholder,
.reviews .review-block .leave-review .review-input:-ms-input-placeholder,
.reviews .review-block .leave-review .review-input:-moz-placeholder,
.reviews .review-block .leave-review .review-input::placeholder {
  font: normal 0.875rem Lato-Regular, sans-serif;
  line-height: 1.25rem;
  color: #26303d;
}
.reviews .review-block .leave-review .review-textarea {
  height: 163px;
  resize: none;
}
.reviews .review-block .leave-review .review-files {
  display: none;
}
.reviews .review-block .leave-review .review-files-label {
  width: 52px;
  height: 52px;
  margin: 0;
  margin-right: 20px;
  background: url("/assets/img/icons/clip.svg") no-repeat center;
  background-size: 29px 26px;
  border: 2px dashed #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
}
.reviews .review-block .leave-review .review-send {
  width: 80%;
  height: 52px;
  padding-right: 15px;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 3.25rem;
  color: #26303d;
  background: url("/assets/img/icons/right-arrow.svg") no-repeat center right 85px #ffb142;
  border: none;
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .reviews .review-block .leave-review .review-send {
    background: #ffb142;
  }
}
.reviews .review-block .leave-review .input-line {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviews .review-block .leave-review .showHere {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.reviews .review-block .leave-review .showHere .attach-file {
  position: relative;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.reviews .review-block .leave-review .showHere .attach-file img {
  width: auto;
  height: 100%;
}
.reviews .review-block .leave-review .showHere .attach-file .attach-file-delete {
  display: none;
}
.faq-page .faq-block {
  margin-bottom: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
}
.faq-page .faq-block .question {
  position: relative;
  padding: 30px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  background: #fff4e8;
  border-radius: 5px;
}
.faq-page .faq-block .question:before {
  content: '';
  position: absolute;
  top: 40px;
  right: 25px;
  width: 22px;
  height: 12px;
  background: url("/assets/img/icons/arrow_down.svg") no-repeat center;
  background-size: contain;
  transition: 0.3s 0s ease;
}
@media only screen and (max-width: 768px) {
  .faq-page .faq-block .question {
    padding: 20px;
    padding-right: 50px;
  }
}
.faq-page .faq-block .answer {
  display: none;
  padding: 30px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
.faq-page .faq-block.active .question:before {
  transform: rotate(180deg);
}
.contacts-page {
  column-count: 2;
}
.contacts-page .contact-block {
  padding-left: 30px;
  margin-bottom: 50px;
}
.contacts-page .contact-block .contact-title {
  margin-bottom: 5px;
  font: bold 1.25rem Lato-Bold, sans-serif;
  line-height: 1.5rem;
  color: #26303d;
}
.contacts-page .contact-block .contact-text {
  min-height: 44px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
@media only screen and (max-width: 768px) {
  .contacts-page {
    column-count: 1;
  }
  .contacts-page .contact-block {
    margin-bottom: 15px;
  }
  .contacts-page .contact-block .contact-text {
    min-height: auto;
  }
}
.contacts-page .address {
  background: url("/assets/img/icons/maps-and-flags.svg") no-repeat 5px 5px;
}
.contacts-page .work-hours {
  background: url("/assets/img/icons/clock-circular-outline.svg") no-repeat 5px 5px;
}
.contacts-page .phone {
  background: url("/assets/img/icons/call-answer.svg") no-repeat 5px 5px;
}
.contacts-page .email {
  background: url("/assets/img/icons/mail-black-envelope-symbol.svg") no-repeat 5px 5px;
}
.contacts-info {
  margin-top: 35px;
  margin-bottom: 135px;
}
@media only screen and (max-width: 768px) {
  .contacts-info {
    margin-bottom: 30px;
    overflow-x: scroll;
  }
}
.contacts-info .company-names {
  margin-bottom: 35px;
  column-count: 3;
}
.contacts-info .company-names .name-block .name-title {
  font: bold 1.25rem Lato-Bold, sans-serif;
  line-height: 1.5rem;
  color: #26303d;
}
.contacts-info .company-names .name-block .name-text {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
}
@media only screen and (max-width: 768px) {
  .contacts-info .company-names {
    column-count: 1;
  }
  .contacts-info .company-names .name-block {
    margin-bottom: 15px;
  }
}
.contacts-info table {
  width: 100%;
}
.contacts-info table tr td {
  height: 44px;
  min-width: 210px;
}
.about-page .page-seo {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}
@media only screen and (max-width: 768px) {
  .about-page .page-seo {
    flex-wrap: wrap;
  }
}
.about-page .about-slider {
  overflow: hidden;
  margin-bottom: 30px;
}
.about-page .about-slider .about-slide {
  cursor: zoom-in;
}
.about-page .about-slider .about-slide:before {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: url("/assets/img/icons/search.svg") no-repeat center #23ad7b;
  background-size: 23px;
  opacity: 0;
  transition: 0.3s 0s ease;
}
.about-page .about-slider .about-slide:hover:before {
  opacity: 1;
}
.about-title {
  margin-bottom: 30px;
  font: normal 1.75rem BloggerSans-Medium, sans-serif;
  line-height: 2.25rem;
  color: #26303d;
}
.tv-reports .report-block {
  position: relative;
  margin-bottom: 30px;
}
.tv-reports .report-block:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: medLevels;
  width: 100%;
  height: 100%;
  background: url("/assets/img/icons/play-button.svg") no-repeat center;
  background-size: 79px;
  transition: 0.3s 0s ease;
}
.tv-reports .report-block:hover:before {
  background-size: 120px;
}
.tv-reports .cover-wrap {
  max-height: 312px;
  overflow: hidden;
}
.tv-reports .cover-wrap img {
  width: 100%;
  margin-bottom: 0;
}
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}
@media only screen and (max-width: 768px) {
  .error-page {
    min-height: 300px;
  }
}
.error-page .error-img {
  max-width: 500px;
}
.error-page .error-title {
  margin: 27px 0 15px;
  font: bold 1.25rem Lato-Bold, sans-serif;
  line-height: 1.5rem;
  color: #26303d;
  text-align: center;
}
.error-page .error-text {
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: defColors;
  text-align: center;
}
.error-page .error-text a {
  display: inline;
  text-decoration: underline;
}
.oamtc-page {
  margin-bottom: 15px;
}
.oamtc-page p {
  margin: 15px 0;
}
.oamtc-page img {
  display: inline;
  width: auto;
  margin-bottom: 30px;
  max-width: 100%;
}
.tv-reports {
  margin-bottom: 0;
}
.tv-reports p {
  margin-top: 15px;
}
.oficial-links .links-title {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: defColors;
}
.oficial-links a {
  display: inline;
  margin-left: 5px;
}
.adac-page {
  font-size: 1.125rem;
}
.adac-page img {
  max-width: 100%;
  height: auto;
}
.adac-page .page-links {
  display: flex;
  flex-wrap: wrap;
  max-width: 315px;
  margin-top: 30px;
  padding: 30px 35px;
  background-color: #fff4e8;
  border-radius: 5px;
}
.adac-page .page-links .go-to-link {
  margin-bottom: 25px;
  border-bottom: 1px solid #26303d;
}
.adac-page .adac-rates {
  margin-top: 30px;
}
.adac-page .adac-rates .rate-symbols {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 65px;
}
@media only screen and (max-width: 768px) {
  .adac-page .adac-rates .rate-symbols {
    margin-bottom: 30px;
  }
}
.adac-page .adac-rates .rate-symbols .symbol-block {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 15px;
}
.adac-page .adac-rates .rate-symbols .symbol-block .symbol-img {
  width: 22px;
  height: 22px;
}
.adac-page .adac-rates .rate-symbols .symbol-block .symbol-text {
  margin-left: 10px;
  line-height: 1.375rem;
}
.adac-page .adac-rates .sorting-directions {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
}
.adac-page .adac-rates .sorting-directions span {
  display: block;
  width: 100%;
}
.adac-page .adac-rates .sorting-directions .sort-type {
  margin-top: 10px;
  margin-right: 35px;
  font: normal 1.125rem Lato-Regular, sans-serif;
  line-height: 1.625rem;
  color: #26303d;
  border-bottom: 1px dashed #26303d;
  transition: 0.3s 0s ease;
  cursor: pointer;
}
.adac-page .adac-rates .sorting-directions .sort-type:hover {
  color: #23ad7b;
  border-color: #23ad7b;
}
.adac-page .adac-rates .sorting-directions .sort-type.active {
  font: bold 1.125rem Lato-Bold, sans-serif;
  line-height: 1.625rem;
  color: #23ad7b;
  border-bottom: 1px solid #23ad7b;
}
.adac-page .adac-rates .results-list {
  margin-top: 30px;
}
.adac-page .adac-rates .results-list .product-item.horizontal {
  display: flex;
  min-height: auto;
  height: auto;
  padding: 15px 18px;
  margin-bottom: 30px;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-img {
  height: auto;
  width: 130px;
  flex-shrink: 0;
  margin-right: 15px;
}
@media only screen and (max-width: 992px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-img {
    width: 160px;
  }
}
@media only screen and (max-width: 768px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-img {
    width: 200px;
  }
}
@media only screen and (max-width: 576px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-img {
    width: 270px;
    max-width: 100%;
  }
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: auto;
  min-height: auto;
  padding: 0;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info .product-name {
  width: 100%;
  font-family: BloggerSans-Regular, sans-serif;
  font-size: 1.5rem;
  line-height: 48px;
  font-weight: 700;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols {
  height: 23px;
  margin: 0;
}
@media only screen and (max-width: 992px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 576px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols {
    height: auto;
  }
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols .symbol-block {
  margin-right: 15px;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols .symbol-block .symbol-text {
  margin: 0;
  margin-right: 10px;
  font-size: 0.875rem;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-info .rate-symbols .symbol-block .symbol-img {
  width: 15px;
  height: 15px;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 576px) {
  .adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line {
    width: 100%;
  }
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line .old-price {
  margin: 0;
  line-height: 1.875rem;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line .product-price {
  margin: 0;
  line-height: 1.875rem;
}
.adac-page .adac-rates .results-list .product-item.horizontal .product-prices .prices-line .product-buy {
  width: 117px;
  margin: 0;
}
.adac-page .adac-rates .results-list .product-item.horizontal .info-line {
  display: flex;
  flex-wrap: wrap;
}
.adac-page .adac-rates .results-list .product-item.horizontal .info-line .rate-number {
  margin: 0 15px;
  font: bold 1.625rem Lato-Bold, sans-serif;
  line-height: 1rem;
  color: #26303d;
}
@media only screen and (max-width: 1200px) {
  .adac-page .adac-rates .results-list .product-item.horizontal {
    flex-wrap: wrap;
  }
}
.adac-page .adac-rates .load-more {
  width: 203px;
  height: 52px;
  font: normal 1.25rem BloggerSans-Regular, sans-serif;
  line-height: 3.25rem;
  color: defColors;
  text-align: center;
  background: #ffb142;
  border: none;
  border-radius: 5px;
}
.testing {
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}
.mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
}
.info-line.mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .info-line.mobile {
    display: flex !important;
  }
}
.map-block {
  height: 500px;
}
