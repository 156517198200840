font-url(file)
	return '../fonts/' + file

webfont(family, file, hack-chrome-windows = false, weight = normal)
	@font-face
		font-family family
		src url(font-url(file + '.eot'))
		src url(font-url(file + '.eot?#iefix')) format('embedded-opentype'),
		url(font-url(file + '.woff')) format('woff'),
		url(font-url(file + '.ttf')) format('truetype')
		font-weight weight
		font-style normal
	if hack-chrome-windows
		@media screen and (-webkit-min-device-pixel-ratio: 0)
			@font-face
				font-family family
				src url(font-url(file + '.svg#' + family)) format('svg')

transition(element = all, duration = 0s, timeFunc = ease)
	transition element duration timeFunc

rem($value)
	return unit($value / 16, 'rem')

placeholder(col)
	color col

// Colors
defColor = #26303D
vsColor = #fff
secColor = #23AD7B
trdColor = #FFB142
atnColor = #FF714F
brdColor = #E5E5E5
dbrdColor = #bbb
bgColor = #FFF4E8

// Fonts
ltFont = OpenSans-Light

defFont = Lato-Regular
bdFont = Lato-Bold

ttFont = BloggerSans-Medium
stFont = BloggerSans-Regular

hdfFont = BadScript-Regular

//z-index levels
bkLevel = -1
bsLevel = 1
medLevel = 5
hiLevel = 10
tpLevel = 20

gradMxn(start,end)
	background start
	background -moz-linear-gradient(270deg, start 30%, end 70%)
	background -webkit-linear-gradient(270deg, start 30%, end 70%)
	background -o-linear-gradient(270deg, start 30%, end 70%)
	background -ms-linear-gradient(270deg, start 30%, end 70%)
	background linear-gradient(0deg, start 30%, end 70%)
	transition .8s

	&:hover
		background end
		background -moz-linear-gradient(270deg, start 0%, end 100%)
		background -webkit-linear-gradient(270deg, start 0%, end 100%)
		background -o-linear-gradient(270deg, start 0%, end 100%)
		background -ms-linear-gradient(270deg, start 0%, end 100%)
		background linear-gradient(0deg, start 0%, end 100%)

//animations

@keyframes pulse
	0%
		transform scale(1)
		opacity 0
	50%
		opacity .4
	100%
		transform scale(1.5)
		opacity 0

@keyframes wiggle
	0%
		transform translateX(0)
	25%
		transform translateX(-10px)
	75%
		transform translateX(10px)
	100%
		transform translateX(0)

@keyframes rotate
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)